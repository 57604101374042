import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { checkHour } from "../functions/checkHour";
import "../css/main.css";

const dims = {
  // a0: [1189, 841],
  // a1: [841, 594],
  // a2: [594, 420],
  // a3: [420, 297],
  a4: [210, 297],
  //a5: [210, 148],
  letter: [215.9, 279.4],
};

export const createPDFMap = (
  title,
  map,
  theAddress,
  format = "a4",
  resolution = 150
) => {
  const dim = dims[format];
  const width = Math.round((dim[0] * resolution) / 25.4);
  const height = Math.round((dim[1] * resolution) / 25.4);
  const size = map.getSize();
  const viewResolution = map.getView().getResolution();

  map.once("rendercomplete", () => {
    const mapCanvas = document.createElement("canvas");
    mapCanvas.setAttribute("crossorigin", "anonymous");
    mapCanvas.width = width;
    mapCanvas.height = height;
    const mapContext = mapCanvas.getContext("2d");

    Array.prototype.forEach.call(
      document.querySelectorAll(".ol-layer canvas"),
      (canvas) => {
        if (canvas.width > 0) {
          const opacity = canvas.parentNode.style.opacity;
          mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
          const transform = canvas.style.transform;
          // Get the transform parameters from the style's transform matrix
          const matrix = transform
            .match(/^matrix\(([^\(]*)\)$/)[1]
            .split(",")
            .map(Number);
          // Apply the transform to the export map context
          CanvasRenderingContext2D.prototype.setTransform.apply(
            mapContext,
            matrix
          );
          mapContext.drawImage(canvas, 0, 0);
        }
      }
    );
    mapContext.globalAlpha = 1;
    mapContext.setTransform(1, 0, 0, 1, 0, 0);
    const pdf = new jsPDF("portrait", undefined, format);
    pdf.addImage(
      mapCanvas.toDataURL("image/jpeg"),
      "JPEG",
      0,
      0,
      dim[0],
      dim[1]
    );
    pdf.addPage(format);
    pdf.setFont("Arial");
    pdf.setFontSize(16);
    pdf.setFont("bold");
    pdf.text(title, 10, 10);
    pdf.setFontSize(14);

    let tableData = [];

    let pp = "";
    theAddress.forEach((o) => {
      tableData.push([
        pp != o.PP ? `${o.PP}.` : "",
        `${o.NOM} ${o.OBSER != "" ? `(${o.OBSER})` : ""}`,
      ]);
      if (pp != o.PP) {
        pp = o.PP;
      }
    });

    autoTable(pdf, {
      columnStyles: {
        0: { cellWidth: 10, cellPadding: 1 },
        1: { cellPadding: 1 },
      },
      body: tableData,
    });
    pdf.save(`${title}.pdf`);
    // Reset original map size
    map.setSize(size);
    map.getView().setResolution(viewResolution);
  });

  // Set print size
  const printSize = [width, height];
  map.setSize(printSize);
  const scaling = Math.min(width / size[0], height / size[1]);
  map.getView().setResolution(viewResolution / scaling);
};

export const createPDFSchedule = (
  title,
  head,
  data,
  format = "a4",
  print = false
) => {
  console.log(head);
  const pdf = new jsPDF("portrait", "pt", format);
  //pdf.addPage(format);
  /*pdf.setFont("Arial");
  pdf.setFontSize(16);
  pdf.setFont("bold");
  pdf.text(title, 10, 10);
  pdf.setFontSize(14);*/

  const table = document.createElement("table");
  var container = `<html><body><table style='width:600px' align='center' border=1px><tr style="font-size:10px; text-align:center;">`;
  var newRow = table.insertRow();
  newRow.className = "printTitleSchedule";
  head.forEach((o) => {
    container += `<td style='padding:5px;'><b>${o}</b></td>`;
    var newCell = newRow.insertCell();
    var newText = document.createElement("strong");
    newText.appendChild(document.createTextNode(o));
    newCell.appendChild(newText);
  });
  container += `</tr>`;
  //let tableData = [];
  console.log(data);
  var bgColor = "#E6E6E6";
  data.forEach((o) => {
    newRow = table.insertRow();
    container += `<tr style='padding:3px' valign=top>`;
    //tableData.push([]);
    //var currentRow = tableData[tableData.length - 1];
    o.forEach((h) => {
      var newCell = newRow.insertCell();
      if (h.day == 1) {
        if (bgColor == "#E6E6E6") {
          bgColor = "white";
        } else {
          bgColor = "#E6E6E6";
        }
      }
      container += `<td style="padding:3px; font-size:10px; background-color:${bgColor}"><b>${h.day}</b>`;
      newCell.innerHTML = `<b>${h.day}</b><br>`;
      /*var newText = document.createElement("strong");
      newText.appendChild(document.createTextNode(h.day));
      newCell.appendChild(newText);*/
      h.activities.forEach((z) => {
        //var newDiv = document.createElement("div");
        container += `<div><b>${z.name}</b><br>${checkHour(z.hour)}</div>`;
        newCell.innerHTML += `<div style="padding:3px; font-size:12px"><b>${
          z.name
        }</b><br>${checkHour(z.hour)}</div>`; //
        //newCell.appendChild(newDiv);
      });

      container += `</td>`;
      //currentRow.push("<b>" + h.day + "</b>");
    });
    container += `</tr>`;
  });

  container += `</table></body></html>`;

  pdf.html(container, {
    callback: (pdf) => {
      pdf.save();
    },
  });
  let cellWidth = 27;
  /*autoTable(pdf, {
    html: table,
    //theme: "grid",
    columnStyles: {
      0: { cellWidth: cellWidth, cellPadding: 1 },
      1: { cellWidth: cellWidth, cellPadding: 1 },
      2: { cellWidth: cellWidth, cellPadding: 1 },
      3: { cellWidth: cellWidth, cellPadding: 1 },
      4: { cellWidth: cellWidth, cellPadding: 1 },
      5: { cellWidth: cellWidth, cellPadding: 1 },
      6: { cellWidth: cellWidth, cellPadding: 1 },
    },
    tableWidth: 180,
    //head: [head],
    //body: tableData,
  });*/

  /*if (print) {
    pdf.autoPrint();
    //This is a key for printing
    pdf.output("dataurlnewwindow");
  } else {
    pdf.save(`${title}.pdf`);
  }*/
};

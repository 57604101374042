import { Point } from "ol/geom";
import Feature from "ol/Feature";
import {
  Icon,
  Style,
  Circle,
  Stroke,
  Fill,
  Text,
  RegularShape,
} from "ol/style";
import { fromLonLat } from "ol/proj";

import flagicon from "../flag.png";

import { colors, emptys } from "../../data/main";

import { avatars } from "../../data/avatars";

export const createMarker = (data, type, _layer, _keep, _callback, _result) => {
  var t_point = data["PUNTOS"];
  if (!_keep) {
    if (type === "np") {
      t_point = type + "_" + data["PUNTOS"];
      if (data.COORDS) {
        _keep = data.COORDS;
      } else {
        _keep = fromLonLat([data.LAT2, data.LON2]);
      }
    } else {
      _keep = fromLonLat([data.LAT, data.LON]);
    }
  }

  //console.log(data['PUNTOS'])

  var tcolor = false;

  if (type === "point") {
    if (!emptys.includes(data.COLOR)) {
      tcolor = [...colors.single];
      tcolor[0] = data.COLOR;
    }
  }

  const iconFeature = new Feature({
    id: t_point,
    point: data.SEC,
    section: data.SECTION,
    obs: data.OBSER,
    group: type,
    color: tcolor,
    map: data.TER,
    geometry: _keep !== -1 ? new Point(_keep) : null,
  });
  var iconStyle = false;

  switch (type) {
    case "avatar":
      if (data.avatar < 1 || data.avatar > 12) {
        data.avatar = 1;
      }
      iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 100],
          anchorXUnits: "fraction",
          anchorYUnits: "pixels",
          opacity: 1.0,
          src: avatars["ani" + data.avatar],
          scale: 0.2,
        }),
        text: new Text({
          text: data.name,
          offsetY: 23,
          font: "bold 18px Calibri,sans-serif",
          fill: new Fill({
            color: "#000",
          }),
        }),
      });
      break;
    case "flag":
      iconStyle = new Style({
        image: new Icon({
          anchor: [0, 28],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: flagicon,
        }),
      });
      break;
    case "point":
      iconStyle = newStyle(data.SEC, data.STATUS);
      break;
    case "np":
      iconStyle = new Style({
        image: new RegularShape({
          points: 4,
          radius: 8,
          radius2: 0,
          angle: Math.PI / 4,
          stroke: new Stroke({ color: "red", width: 2 }),
          fill: new Fill({ color: "red" }),
        }),
        text: new Text({
          text: data.SEC.toString(),
          offsetY: -13,
          font: "bold 18px Calibri,sans-serif",
          fill: new Fill({ color: "#000" }),
        }),
      });
      break;
    default:
      break;
  }

  iconFeature.setStyle(iconStyle);
  //console.log(data)

  if (type === "np" && data.COORDS) {
    _callback(iconFeature);
  }

  _layer.getSource().addFeature(iconFeature);

  if (_result) {
    return iconFeature;
  }
};

export const newStyle = (_number, _color = colors.done) => {
  return new Style({
    image: new Circle({
      radius: 8,
      stroke: new Stroke({ color: _color[1], width: 3 }),
      fill: new Fill({ color: _color[0] }),
    }),
    text: new Text({
      text: _number.toString(),
      offsetY: -15,
      font: "bold 18px Calibri,sans-serif",
      fill: new Fill({ color: "#000" }),
    }),
  });
};

export const writeColor = (_color) => {
  return [_color, colors.single[1]];
};

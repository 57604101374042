import React from "react";
import { CircularProgress } from "@mui/material";

export default function LoadingBox() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "90%",
      }}
    >
      <CircularProgress size={60} style={{ padding: 10 }} />
    </div>
  );
}

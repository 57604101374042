import React from "react";

export default function Section(props) {
  const smallBox = (o, index) => {
    if (o) {
      return (
        <div
          key={index}
          style={o.props.topstyle ?? { paddingBottom: 5, paddingLeft: 30 }}
        >
          {o}
        </div>
      );
    }
  };
  return (
    <div style={{ padding: 0 }}>
      <div
        style={{
          padding: 5,
          paddingLeft: 15,
          fontSize: 20,
          fontWeight: "bolder",
        }}
      >
        {props.title}
      </div>
      {props.children.length > 1
        ? props.children.map((o, index) => smallBox(o, index))
        : smallBox(props.children, 0)}
      {!props.final && (
        <div
          style={{ marginTop: 10, borderBottom: "3px dotted #ebebeb" }}
        ></div>
      )}
    </div>
  );
}

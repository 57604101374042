import React, { useState, useEffect } from "react";
import { strLang } from "../functions/strLang";
import { emptys, tileList } from "../data/main";
import { updateGroup } from "../services/settings";
import { removeAccents } from "../functions/removeAccents";
import SettingsIcon from "@mui/icons-material/Settings";
import Section from "./Section";
import imgOSM from "../images/tiles/OSM.png";
import imgRoadmap from "../images/tiles/Roadmap.png";
import imgTerrain from "../images/tiles/Terrain.png";
import imgAlteredRoadmap from "../images/tiles/Altered_roadmap.png";
import imgSatelliteOnly from "../images/tiles/Satellite_only.png";
import imgHybrid from "../images/tiles/Hybrid.png";
import imgRoad from "../images/tiles/Road.png";
import imgAerial from "../images/tiles/Aerial.png";
import imgAerialLabel from "../images/tiles/AerialLabel.png";
import { avatars } from "../data/avatars";

var tempTile = false;

export default function Settings(props) {
  const [width, setWidth] = useState(0);
  const [users, setUsers] = useState([]);
  const imgList = {
    OSM: imgOSM,
    Roadmap: imgRoadmap,
    Terrain: imgTerrain,
    Altered_roadmap: imgAlteredRoadmap,
    Satellite_only: imgSatelliteOnly,
    Hybrid: imgHybrid,
    Road: imgRoad,
    Aerial: imgAerial,
    AerialLabel: imgAerialLabel,
  };
  const [tileImg, setTileImg] = useState(
    localStorage.getItem("tile")
      ? imgList[localStorage.getItem("tile")]
      : imgList["OSM"]
  );

  useEffect(() => {
    setWidth(props.width);
  }, [props.width]);

  useEffect(() => {
    //console.log(props.users);
    setUsers([...props.users]);
  }, [props.users]);

  const funcUpdateGroup = async (type, o) => {
    const results = await updateGroup(type, o);
    //console.log(results);
    if (results && results.status) {
      localStorage.setItem(
        "tile",
        emptys.includes(results.tile) ? "OSM" : results.tile
      );
    }
    return false;
  };

  const changeTile = (e) => {
    if (tempTile) {
      clearTimeout(tempTile);
    }
    tempTile = setTimeout(() => {
      funcUpdateGroup("MAINGROUP", {
        table: "TILE",
        value: e.target.value,
      });
    }, 1500);

    setTileImg(imgList[e.target.value]);
  };

  const updateUserList = (e) => {
    let txt = e.target.value;
    if (txt === "") {
      setUsers([...props.users]);
    } else {
      setUsers([
        ...props.users.filter((o) => {
          let name = removeAccents(o.NOMBRE);
          return name.toLowerCase().includes(txt.toLowerCase());
        }),
      ]);
    }
  };

  return (
    <div
      className="submenu"
      style={{ width: width + "%", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "#EEEEEE",
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "left",
        }}
      >
        <SettingsIcon style={{ fontSize: 60 }} />
      </div>

      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        <Section title={strLang("data")}>
          <div>
            <label>
              <b>{strLang("name")}</b>
            </label>
            <br />
            {Number(localStorage.getItem("rol")) === 2 ? (
              <input
                defaultValue={localStorage.getItem("groupName") ?? ""}
                disabled
              />
            ) : (
              localStorage.getItem("groupName")
            )}
          </div>
          <div>
            <label>
              <b>{strLang("email")}</b>
            </label>
            <br />
            {Number(localStorage.getItem("rol")) === 2 ? (
              <input
                defaultValue={localStorage.getItem("email") ?? ""}
                disabled
              />
            ) : (
              localStorage.getItem("email")
            )}
          </div>
        </Section>
        <Section title={strLang("map")}>
          <img
            className="tileImg"
            topstyle={{
              padding: 10,
              boxSizing: "border-box",
            }}
            src={tileImg}
            alt={"avatar"}
          />
          <div>
            <select
              defaultValue={localStorage.getItem("tile")}
              onChange={changeTile}
            >
              {tileList.map(
                (o, index) =>
                  index > 0 && (
                    <option key={index} value={o}>
                      {strLang(o, "tileNames")}
                    </option>
                  )
              )}
            </select>
          </div>
        </Section>
        <Section title={strLang("users")} final={true}>
          <input onChange={updateUserList} />
          <div
            style={{
              width: "100%",
              height: 200,
              overflow: "auto",
              border: "#767676 solid 2px",
              boxSizing: "border-box",
            }}
            topstyle={{
              padding: 10,
              boxSizing: "border-box",
            }}
          >
            {users.map((o, index) => {
              return (
                <div key={index}>
                  {o.IMA > 0 && (
                    <img
                      src={avatars["ani" + o.IMA]}
                      alt="avatar"
                      style={{ width: 50 }}
                    />
                  )}
                  {o.NOMBRE}
                </div>
              );
            })}
          </div>
        </Section>
      </div>
    </div>
  );
}

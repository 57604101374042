import React, { useState, useEffect } from "react";

import Territories from "../Territories/Territories";
import Search from "../Search/Search";
import TheMap from "../TheMap/TheMap";
import Schedule from "../Schedule/Schedule";
//import Userslist from "../Userslist/Userslist";
import Settings from "../Settings/Settings";
import Help from "../Settings/Help";

//import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
//import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import PublicIcon from "@mui/icons-material/Public";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
//import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HelpIcon from "@mui/icons-material/Help";

import { sendPostServices } from "../services/services";
import { getCurrentDate } from "../utilities/getCurrentDate";
import { strLang } from "../functions/strLang";
import Swal from "sweetalert2";
import { user } from "../data/main";
import { getMainInfo } from "../services/menu";

const style = { padding: 5, fontSize: 40, cursor: "pointer" };

var counter = true;
var gpscounter = true;
var allMaps = [];
var allAddrs = [];

export default function Menu({ logout, login }) {
  const [coords, setCoords] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [maps, setMaps] = useState(false);
  const [data, setData] = useState(false);
  const [addresses, setAddresses] = useState(false);
  const [users, setUsers] = useState([]);

  //const [value, setValue] = useState(0);
  const [menu, setMenu] = useState({
    territories: { width: 0, color: "#FF8300" },
    address: { width: 0, color: "red" },
    map: { width: 0, color: "green" },
    userslist: { width: 0, color: "#5500FF" },
    schedule: { width: 100, color: "#0400FF" },
    historial: { width: 0, color: "#EC6800" },
    settings: { width: 0, color: "#C500FF" },
    help: { width: 0, color: "#2E45D6" },
  });

  const getApiData = async (tempData, renew = true) => {
    if (localStorage.getItem("logged")) {
      if (tempData["type"] === "avatars") {
        tempData["ter"] = user.ter;
      }
      if (tempData["type"] === "avatars" && menu["map"].width === 0) {
        setTimeout(() => {
          getApiData(tempData);
        }, 10000);
        return;
      }

      const response = await getMainInfo(tempData);

      //console.log("api response: ", response);

      switch (tempData["type"]) {
        case "all":
          response["maps"].map((o) => {
            o["FLAGS"] = response["points"].filter(
              (v) => String(v.TER) === String(o.ID) && Number(v.SEC) === 0
            ).length;
            let _addrs = response["address"].filter(
              (v) => String(v.IDTER) === String(o.ID)
            );
            o["NP"] = response["points"].filter(
              (v) => String(v.TER) === String(o.ID) && v.LAT2 && v.LON2
            ).length;
            /*o["POINTS"] = response["points"].filter(
              (v) =>
                String(v.TER) === String(o.ID) && v.LAT2 && v.LON2 && v.SEC > 0
            ).length;*/
            o["TOTAL"] = _addrs.length;
            o["NOTES"] = response["address"].filter(
              (v) => String(v.IDTER) === String(o.ID) && v.NOTAS.length > 0
            ).length;
            o["HECHOS"] = _addrs.filter((o) => o.EST > 0).length;
            let boxhist = {};
            response["historial"]
              .filter((v) => String(v.TER) === String(o.ID))
              .map((h) => {
                let ddd = h.FECHA.split(" ");
                ddd[0] = ddd[0].split("-");
                let _idx = "m" + (Number(ddd[0][1]) - 1).toString();
                let _idx2 = Number(ddd[0][2]);
                if (!boxhist[_idx]) {
                  boxhist[_idx] = [];
                }
                if (!boxhist[_idx][_idx2]) {
                  boxhist[_idx][_idx2] = [];
                }

                boxhist[_idx][_idx2].push({
                  DATE: ddd[0].join("-"),
                  HOUR: ddd[1],
                  PER: h.PER,
                  POS: Math.round((Number(ddd[0][2]) * 100) / 31),
                });
                return true;
              });

            o["HISTORIAL"] = boxhist;
            return true;
          });
          allMaps = response["maps"];
          //console.log(allMaps);
          allAddrs = response["address"];
          setMaps(response["maps"]);
          setAddresses(response["address"]);
          response["users"].map((o) => {
            let tname = response["maps"].filter(
              (v) => Number(v.ID) === Number(o.TER)
            );
            let tavar = avatars.filter((v) => Number(v.ORD) === Number(o.ORD));
            if (tname.length > 0) {
              o.TERNAME = tname[0].NOM;
            } else {
              o.TERNAME = "";
            }
            if (tavar.length > 0) {
              o.ACTIVE = true;
            } else {
              o.ACTIVE = false;
            }
            return true;
          });
          setUsers(response["users"]);
          break;
        case "map":
          response["address"] = response["address"].sort((a, b) => a.PP - b.PP);
          user.ter = tempData["code"];
          user.tername = tempData.name;
          if (response["limits"].length > 0) {
            user.date = response["limits"][0]["MDATE"];
          }
          setData({
            id: tempData["code"],
            title: tempData.name,
            mapTile:
              tempData["code"] === "all" ? "" : response["limits"][0]["TILE"],
            centerpoint: tempData.point,
            address: response["address"],
            points: response["points"],
            limits: response["limits"],
            sections: response["sections"],
            maps: allMaps,
            renew: renew,
          });
          break;
        case "avatars":
          if (response && response["avatars"]) {
            if (
              JSON.stringify(avatars) !== JSON.stringify(response["avatars"])
            ) {
              setAvatars(response["avatars"]);
            }
            if (
              String(user.date) !== String(response["date"]) &&
              user.ter > 0
            ) {
              user.date = response["date"];
              getApiData(
                {
                  type: "map",
                  code: user.ter,
                  name: user.tername,
                  point: false,
                },
                false
              );
            }
            setTimeout(() => {
              getApiData(tempData);
            }, 5000);
          } else {
            setTimeout(() => {
              getApiData(tempData);
            }, 20000);
          }
          break;
        default:
          break;
      }
    }
    return true;
  };

  const openMap = (id, name, point = false) => {
    if (id !== "all") {
      user.ter = id;
      localStorage.setItem("mapid", id);
      localStorage.setItem("mapname", name);
    }

    getApiData({
      type: "map",
      code: id,
      name: name,
      point: point,
      group: id === "all" ? localStorage.getItem("code2") : false,
    });
    switchBox("map");
  };

  const sendPosition = async (pos) => {
    let sendPositionPayload = {
      type: "pos",
      id: localStorage.getItem("id"),
      lat: pos[0],
      lng: pos[1],
      date: getCurrentDate(),
    };
    const response = await sendPostServices(sendPositionPayload);

    setTimeout(() => {
      counter = true;
    }, 3000);
  };

  const closeSession = () => {
    //localStorage.clear()
    localStorage.removeItem("token");
    localStorage.removeItem("code2");
    localStorage.removeItem("rol");
    localStorage.removeItem("logged");
    localStorage.removeItem("day");
    localStorage.removeItem("id");
    logout();
  };

  const gpsOk = (position) => {
    const { latitude, longitude } = position.coords; //accuracy, altitude, heading, speed
    if (
      latitude !== coords.latitude &&
      longitude !== coords.longitude &&
      counter
    ) {
      counter = false;
      sendPosition([latitude, longitude]);
    }
    if (gpscounter) {
      gpscounter = false;
      setCoords(position.coords);
      setTimeout(() => {
        gpscounter = true;
      }, 1000);
    }

    //if (target.latitude === crd.latitude && target.longitude === crd.longitude) {
    //  navigator.geolocation.clearWatch(id);
    //}
  };

  const gpsError = (err) => {
    //setCoords(false)
    console.error(`ERROR(${err.code}): ${err.message}`);
  };

  useEffect(() => {
    const mpInit = async () => {
      if (
        localStorage.getItem("avatar") == "null" ||
        localStorage.getItem("day") != getCurrentDate()
      ) {
        closeSession();
        Swal.close();
      }

      navigator.geolocation.watchPosition(gpsOk, gpsError, {
        //let gpsID =
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0,
      });

      /*if (window.DeviceOrientationEvent) {
              // Listen for the deviceorientation event and handle the raw data
              window.addEventListener('deviceorientation', (event) => {
                var compassdir;
                var conver
                if(event.webkitCompassHeading) {
                // Apple works only with this, alpha doesn't work
                compassdir = event.webkitCompassHeading; 
                conver=compassdir * Math.PI / -180;
                } else {
                  compassdir = event.alpha;
                  conver=compassdir * Math.PI / 180;
                }
                //alert(conver)
              //   if (activeLook===3) {
              //     mainView.animate({rotation: conver, duration: 500})
              //   }
              });
          }*/

      await getApiData({
        type: "all",
        code: localStorage.getItem("code2"),
        maincode: localStorage.getItem("code"),
        id: localStorage.getItem("id"),
      });
      await getApiData({
        type: "avatars",
        code: localStorage.getItem("code"),
        id: localStorage.getItem("id"),
        day: localStorage.getItem("day"),
      });
      if (
        localStorage.getItem("mapid") &&
        localStorage.getItem("menu") === "map"
      ) {
        openMap(
          localStorage.getItem("mapid"),
          localStorage.getItem("mapname"),
          "reload"
        );
      } /* else if (!emptys.includes(localStorage.getItem('menu'))) {
              console.log(localStorage.getItem('menu'))
              switchBox(localStorage.getItem('menu'))
          }*/
      /*fetch('/mock-geojson-api.json')
            .then(response => response.json())
            .then( (fetchedFeatures) => {
              // parse fetched geojson into OpenLayers features
              //  use options to convert feature from EPSG:4326 to EPSG:3857
              const wktOptions = {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
              }
              const parsedFeatures = new GeoJSON().readFeatures(fetchedFeatures, wktOptions)
      
              // set features into state (which will be passed into OpenLayers
              //  map component as props)
      
              navigator.geolocation.getCurrentPosition(function (position) {
                parsedFeatures[0]['coords'] = {
                  lat:position.coords.latitude,
                  lon:position.coords.longitude
                }
                setFeatures(parsedFeatures)
              });
            })*/
    };
    mpInit();
  }, []);

  const switchBox = (newValue) => {
    let tMenu = { ...menu };
    for (const key of Object.keys(tMenu)) {
      tMenu[key].width = 0;
    }
    tMenu[newValue].width = 100;
    localStorage.setItem("menu", newValue);
    //setValue(newValue);
    setMenu(tMenu);
  };

  const menuColor = (o) => {
    return menu[o].width === 100 ? menu[o].color : "gray";
  };

  const updateMap = (id, points, addrs) => {
    if (!isNaN(id)) {
      allAddrs = allAddrs.filter((o) => o.IDTER != id).concat(addrs);
      let index = allMaps.findIndex((o) => o.ID == id);
      allMaps[index].FLAGS = points.filter((o) => Number(o.SEC) === 0).length;
      allMaps[index].NP = points.filter((o) => o.LAT2 && o.LON2).length;
      allMaps[index].TOTAL = addrs.length;
      allMaps[index].NOTES = addrs.filter((o) => o.NOTAS.length > 0).length;
      allMaps[index].HECHOS = addrs.filter((o) => o.EST > 0).length;
      setMaps([...allMaps]);
      setAddresses([...allAddrs]);
    }
  };

  const reloadMap = () => {
    getApiData({
      type: "all",
      code: localStorage.getItem("code2"),
      maincode: localStorage.getItem("code"),
      id: localStorage.getItem("id"),
    });
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        border: 1,
        overflow: "hidden",
      }}
    >
      <div style={{ position: "relative", flex: 1 }}>
        <Territories
          width={menu["territories"].width}
          maps={maps}
          openMap={openMap}
          reload={reloadMap}
        />
        <Search
          width={menu["address"].width}
          addresses={addresses}
          openMap={openMap}
          reload={reloadMap}
        />
        <TheMap
          width={menu["map"].width}
          gps={coords}
          data={data}
          updateMap={updateMap}
          avatars={avatars}
        />
        {/*Number(localStorage.getItem("rol")) > 0 && (
          <Userslist width={menu["userslist"].width} users={users} />
        )*/}
        <Schedule
          width={menu["schedule"].width}
          login={login}
          maps={maps}
          openMap={openMap}
        />
        <Settings width={menu["settings"].width} users={users} />
        <Help width={menu["help"].width} />
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: "#ECECEC",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          borderTop: "solid 2px black",
        }}
      >
        <CalendarMonthIcon
          onClick={() => {
            switchBox("schedule");
          }}
          style={style}
          sx={{ color: menuColor("schedule") }}
        />
        <MapIcon
          onClick={() => {
            switchBox("territories");
          }}
          style={style}
          sx={{ color: menuColor("territories") }}
        />
        <LocationOnIcon
          onClick={() => {
            switchBox("address");
          }}
          style={style}
          sx={{ color: menuColor("address") }}
        />
        <PublicIcon
          onClick={() => {
            switchBox("map");
          }}
          style={style}
          sx={{ color: menuColor("map") }}
        />
        {Number(localStorage.getItem("rol")) > 0 && (
          <>
            {/* <PersonIcon
              onClick={() => {
                switchBox("userslist");
              }}
              style={style}
              sx={{ color: menuColor("userslist") }}
            /> */}
            <SettingsIcon
              onClick={() => {
                switchBox("settings");
              }}
              style={style}
              sx={{ color: menuColor("settings") }}
            />
          </>
        )}

        <HelpIcon
          onClick={() => {
            switchBox("help");
          }}
          style={style}
          sx={{ color: menuColor("help") }}
        />
        <LogoutIcon
          onClick={() => {
            Swal.fire({
              title: strLang("wantexit"),
              confirmButtonText: strLang("exit"),
              confirmButtonColor: "red",
              showCancelButton: true,
              cancelButtonText: strLang("cancel"),
            }).then((result) => {
              if (result.isConfirmed) {
                closeSession();
              }
            });
          }}
          style={style}
        />
      </div>
    </div>
  );
}

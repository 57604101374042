import React, { useState, useEffect, useMemo } from "react";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import "../css/main.css";
import { strLang } from "../functions/strLang";
import { removeAccents } from "../functions/removeAccents";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReplayIcon from "@mui/icons-material/Replay";
import LoadingBox from "../LoadingBox";
import NoResults from "../NoResults";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

var tempOut = false;

export default function Search(props) {
  const [_width, set_Width] = useState(0);
  const [mainTxt, setMainTxt] = useState("");
  const [loading, setLoading] = useState(-1);
  const list = useMemo(
    () => filterAddresses(props.addresses, mainTxt),
    [mainTxt]
  );

  const writeList = list.map((o, index) => (
    <Grid
      key={index}
      onClick={() => {
        Swal.fire({
          title: strLang("openthispoint"),
          showCancelButton: true,
          confirmButtonText: strLang("open"),
          confirmButtonColor: "#5091FF",
          cancelButtonText: strLang("cancel"),
        }).then((result) => {
          if (result.isConfirmed) {
            props.openMap(o.IDTER, o.NTER, o.PUNTO);
          }
        });
      }}
      item
      xs={1}
      sm={4}
      md={4}
      lg={8}
    >
      <Item style={{ textAlign: "left", cursor: "pointer", height: "100%" }}>
        <b style={{ fontSize: "large" }}>{o.NOM}</b>
        <p style={{ marginBottom: 0, marginTop: 0 }}>[{o.NTER}]</p>
        <p style={{ marginBottom: 0, marginTop: 0 }}>
          {strLang("point")}: {o.SEC}
        </p>
      </Item>
    </Grid>
  ));

  useEffect(() => {
    mainTxt === "" ? setLoading(-1) : setLoading(0);
  }, [props.addresses]);

  useEffect(() => {
    set_Width(props.width);
  }, [props.width]);

  useEffect(() => {
    mainTxt === "" ? setLoading(-1) : setLoading(0);
  }, [list]);

  return (
    <div
      className="submenu"
      style={{ width: _width + "%", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "#EEEEEE",
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {" "}
          <LocationOnIcon style={{ fontSize: 60 }} />
          <TextField
            id="outlined-basic"
            label={strLang("search")}
            variant="outlined"
            style={{ width: 200 }}
            onChange={(e) => {
              setLoading(1);
              if (tempOut) {
                clearTimeout(tempOut);
              }
              tempOut = setTimeout(() => {
                setMainTxt(e.target.value);
              }, 1000);
            }}
          />
          <div
            onClick={() => {
              if (loading !== 1) {
                setLoading(1);
                props.reload();
              }
            }}
            style={{
              backgroundColor: "gray",
              marginLeft: 10,
              borderRadius: 100,
              width: 50,
              height: 50,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReplayIcon style={{ fontSize: 40, color: "white" }} />
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        {loading === -1 ? (
          <></>
        ) : loading === 1 ? (
          <LoadingBox />
        ) : list.length === 0 ? (
          <NoResults />
        ) : (
          <Grid
            container
            spacing={5}
            padding={2}
            columns={{ xs: 1, sm: 8, md: 12, lg: 40 }}
          >
            {writeList}
          </Grid>
        )}
        <div style={{ width: "100%", height: 20 }}></div>
      </div>
    </div>
  );
}

const filterAddresses = (addresses, txt) => {
  txt = txt.trim();
  if (txt === "") {
    return [];
  } else {
    txt = removeAccents(txt).toLowerCase();
    let tempAddr = addresses.filter((o) => {
      if (o.NOM !== "") {
        let name = removeAccents(o.NOM).toLowerCase();

        if (txt.includes(" ")) {
          let parts = txt.split(" ");
          return (
            parts.filter((b) => {
              return name.includes(b.toLowerCase());
            }).length === parts.length
          );
        } else {
          return name.includes(txt);
        }
      }
    });
    tempAddr.length > 200 && (tempAddr.length = 200);
    return tempAddr;
  }
};

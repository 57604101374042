import { sendPostServices } from "../services/services";

export const updateGroup = async (type, o) => {
  try {
    var updateGroupPayload = {
      type,
      code: localStorage.getItem("code2"),
      ...o,
    };
    //console.log("updateGroupPayload: ", updateGroupPayload);
    const response = await sendPostServices(updateGroupPayload);
    //console.log("response updateGroup: ", response);
    return response;
  } catch (error) {
    console.log("updateGroup error: ", error);
  }
  return false;
};

export const user = {
  id: 1,
  name: "user",
  lang: "ing",
  avatar: 12,
  group: 1,
  ter: false,
  tername: false,
  date: false,
};

export const tile = {
  offline: "./offline/panama.pbf",
  OSM: {
    url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
    provider: "OSM",
  },
  Aerial: { type: "Aerial", provider: "Bing" },
  AerialLabel: { type: "AerialWithLabelsOnDemand", provider: "Bing" },
  Road: { type: "RoadOnDemand", provider: "Bing" },
  /*cyclOSM: "https://dev.{a-c}.tile.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
  Roadmap: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
  Terrain: "http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}",
  Altered_roadmap: "http://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}",
  Satellite_only: "http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}",
  Terrain_only: "http://mt0.google.com/vt/lyrs=t&hl=en&x={x}&y={y}&z={z}",
  Hybrid: "http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}",*/
};

export const tileList = ["default", "OSM", "Road", "Aerial", "AerialLabel"];

export const lang = {
  ing: {
    add: "Add",
    addname: "Enter a name",
    addnewaddress: "Add new address",
    addresses: "Addresses",
    addSeccion: "Add section",
    all: "All",
    allterAsk: "Do you want to open all territories?",
    askclean: "Do you want to clear the address markings?",
    cancel: "Cancel",
    change: "Change",
    changename: "Change Name",
    changenumber: "Change Number",
    clean: "Clean",
    close: "Close",
    colorsList: {
      orange: "Orange",
      blue: "Blue",
      red: "Red",
      gray: "Gray",
      yellow: "Yellow",
      violet: "Violet",
    },
    data: "Data",
    date: "Date",
    day: "day",
    days: "days",
    delete: "Delete",
    deleteActivity: "Do you want to delete this activity?",
    deleteArea: "Do you want to remove this limit?",
    deletex: "Remove X",
    delter: "Do you want to delete this territory?",
    edit: "Edit",
    email: "Email",
    end: "End",
    enter: "Enter",
    enterEditor:
      "You are entering as an editor, the changes you make to the territories will not be recorded in the history.",
    enterinfo: "Enter information",
    event: "Event",
    exit: "Logout",
    export: "Export",
    exportPDF: "Do you want to export to PDF?",
    flaginstructions:
      "Each line of text includes a name\n(*) for observations of each line",
    groups: "Groups",
    hour: "Hour",
    initCode: "Code",
    initEnter: "Enter",
    initName: "Name",
    initPassword: "Password",
    accept: "Accept ",
    lastconnection: "Last Connection",
    legalInfo: "Legal Information",
    loading: "Loading...",
    map: "Map",
    month: "month",
    months: "months",
    monthsname: [
      "jan.",
      "feb.",
      "mar.",
      "apr.",
      "may.",
      "jun.",
      "jul.",
      "aug.",
      "sep.",
      "oct.",
      "nov.",
      "dic.",
    ],
    daysName: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    daysNameShort: ["Mon.", "Tues.", "Wed.", "Thurs.", "Fri.", "Sat.", "Sun."],
    missingField: "Missing field: ",
    mostrecent: "Recent",
    mostoverdue: "Overdue",
    name: "Name",
    networkError: "Network error",
    new: "New",
    newflag: "New Flag",
    newnumber: "New Number",
    newter: "New Territory",
    noActivities: "No activities",
    noresults: "No results",
    notes: "Notes",
    observations: "Observations",
    ok: "Ok",
    open: "Open",
    openthispoint: "Do you want to open this point?",
    point: "Point",
    record: "Record",
    relocate: "Relocate",
    save: "Save",
    saving: "Saving...",
    scheduled: "Scheduled",
    search: "Search",
    start: "Start",
    terAsk: "Do you want to open this territory?",
    territories: "Territories",
    territory: "Territory",
    tileNames: {
      default: "Default Settings",
      offline: "Local map",
      OSM: "Roadmap (OSM)",
      Road: "Roadmap (Bing)",
      Aerial: "Satellite (Bing)",
      AerialLabel: "Satellite with labels (Bing)",
      Roadmap: "Roadmap (GM)",
      Terrain: "Terrain",
      Altered_roadmap: "Altered roadmap",
      Satellite_only: "Satelital map",
      Terrain_only: "Terrain only",
      Hybrid: "Hybrid",
    },
    time: "Time",
    title: "Title",
    today: "Today",
    update: "Update",
    users: "Users",
    year: "year",
    years: "years",
    wantclean: "Do you want to clear all annotations?",
    wantdeleteaddress: "Do you want to delete this location?",
    wantexit: "Do you want to log out?",
    whatInsert: "What do you want to add?",
    wantroute: "Do you want to open this route?",
    startLogin: "Starting Login...",
    badCredentials: "Incorrect credentials",
    netWorkError: "Connection error",
    upateError: "Update error",
    pleaseSelect: "Please select: ",
    avatar: "avatar",
    conditionsUse: "Conditions of use",
    conditionsLeyend: [],
    version: "Version",
  },
  esp: {
    add: "Agregar",
    addname: "Ingrese un nombre",
    addnewaddress: "Agregar nueva dirección",
    addresses: "Direcciones",
    addSeccion: "Agregar sección",
    all: "Todos",
    allterAsk: "¿Desea abrir todos los territorios?",
    askclean: "¿Desea limpiar las marcaciones de las direcciones?",
    cancel: "Cancelar",
    change: "Cambiar",
    changename: "Cambiar Nombre",
    changenumber: "Cambiar Número",
    clean: "Limpiar",
    close: "Cerrar",
    colorsList: {
      orange: "Naranja",
      blue: "Azul",
      red: "Rojo",
      gray: "Gris",
      yellow: "Amarillo",
      violet: "Violeta",
    },
    missingField: "Campo incompleto: ",
    data: "Datos",
    date: "Fecha",
    day: "día",
    days: "días",
    delete: "Eliminar",
    deleteActivity: "¿Desea eliminar esta actividad?",
    deletex: "Quitar X",
    deleteArea: "¿Desea eliminar este límite?",
    delter: "¿Desea eliminar este territorio?",
    edit: "Editar",
    email: "Email",
    end: "Fin",
    enter: "Ingresar",
    enterEditor:
      "Usted está entrando como editor, los cambios que haga en los territorios no quedará registrado en el historial.",
    enterinfo: "Ingrese información",
    event: "Evento",
    exit: "Salir",
    export: "Exportar",
    exportPDF: "¿Desea exportar a PDF?",
    flaginstructions:
      "Cada línea de texto incluye un nombre\n(*) para observaciones de cada línea",
    groups: "Grupos",
    hour: "Hora",
    initCode: "Código",
    initEnter: "Ingresar",
    initName: "Nombre",
    initPassword: "Contraseña",
    accept: "Aceptar ",
    lastconnection: "Última Conexión",
    legalInfo: "Información Legal",
    loading: "Cargando...",
    map: "Mapa",
    month: "mes",
    months: "meses",
    monthsname: [
      "ene.",
      "feb.",
      "mar.",
      "abr.",
      "may.",
      "jun.",
      "jul.",
      "ago.",
      "sep.",
      "oct.",
      "nov.",
      "dic.",
    ],
    daysName: [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
      "Domingo",
    ],
    daysNameShort: ["Lun.", "Mar.", "Miérc.", "Juev.", "Vier.", "Sáb.", "Dom."],
    mostrecent: "Recientes",
    mostoverdue: "Atrasados",
    name: "Nombre",
    networkError: "Error de red",
    new: "Nuevo",
    newflag: "Nueva Bandera",
    newnumber: "Nuevo Número",
    newter: "Nuevo Territorio",
    noActivities: "Sin actividades",
    noresults: "Sin resultados",
    notes: "Notas",
    observations: "Observaciones",
    ok: "Ok",
    open: "Abrir",
    openthispoint: "¿Desea abrir este punto?",
    point: "Punto",
    record: "Registro",
    relocate: "Reubicar",
    save: "Guardar",
    saving: "Guardando...",
    scheduled: "Scheduled",
    search: "Buscar",
    start: "Inicio",
    terAsk: "¿Desea abrir este territorio?",
    territories: "Territorios",
    territory: "Territorio",
    tileNames: {
      default: "Configuración por defecto",
      offline: "Mapa local",
      OSM: "Calles (OSM)",
      Road: "Calles (Bing)",
      Aerial: "Satelital (Bing)",
      AerialLabel: "Satelital con etiquetas (Bing)",
      Roadmap: "Mapa de calles (GM)",
      Terrain: "Terreno",
      Altered_roadmap: "Mapa de calles alterados",
      Satellite_only: "Mapa satélital",
      Terrain_only: "Sólo terreno",
      Hybrid: "Hibrido",
    },
    time: "Tiempo",
    title: "Título",
    today: "Hoy",
    update: "Actualizar",
    users: "Usuarios",
    year: "año",
    years: "años",
    wantclean: "¿Desea limpiar todas las anotaciones?",
    wantdeleteaddress: "¿Desea eliminar este local?",
    whatInsert: "¿Qué desea agregar?",
    wantexit: "¿Desea salir?",
    wantroute: "¿Desea marcar esta ruta?",
    startLogin: "Entrando...",
    badCredentials: "Credenciales incorrectas",
    netWorkError: "Error de conexión",
    upateError: "Error al actualizar",
    pleaseSelect: "Por favor seleccione: ",
    avatar: "avatar",
    conditionsUse: "Condiciones de uso",
    conditionsLeyend: [
      "",
      "",
      "¡Bienvenido a MapaCHE!",
      "Estos términos y condiciones describen las reglas y regulaciones para el uso de la app MapaCHE.",
      "Al utilizar esta aplicación, asumimos que aceptas estos términos y condiciones. No continúes usando MapaCHE si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.",
      "",
      "<b>Cookies:</b>",
      "El sitio web utiliza cookies para ayudar a personalizar tu experiencia en línea. Al acceder a MapaCHE, aceptaste utilizar las cookies necesarias.",
      "Una cookie es un archivo de texto que un servidor de páginas web coloca en tu disco duro. Las cookies no se pueden utilizar para ejecutar programas o enviar virus a tu computadora. Las cookies se te asignan de forma exclusiva y solo un servidor web en el dominio que emitió la cookie puede leerlas.",
      "Podemos utilizar cookies para recopilar, almacenar y rastrear información con fines estadísticos o de marketing para operar nuestro sitio web. Tienes la capacidad de aceptar o rechazar cookies opcionales. Hay algunas cookies obligatorias que son necesarias para el funcionamiento de nuestro sitio web. Estas cookies no requieren tu consentimiento ya que siempre funcionan. Ten en cuenta que al aceptar las cookies requeridas, también aceptas las cookies de terceros, que podrían usarse a través de servicios proporcionados por terceros si utilizas dichos servicios en nuestro sitio web, por ejemplo, una ventana de visualización de video proporcionada por terceros e integrada en nuestro sitio web.",
      "",
      "Licencia:",
      "A menos que se indique lo contrario, MapaCHE y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en MapaCHE. Todos los derechos de propiedad intelectual son reservados. Puedes acceder desde MapaCHE para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.",
      "",
      "No debes:",
      "Copiar o volver a publicar material de MapaCHE",
      "Vender, alquilar o sublicenciar material de MapaCHE",
      "Reproducir, duplicar o copiar material de MapaCHE",
      "Redistribuir contenido de MapaCHE",
      "Este acuerdo comenzará el fecha presente.",
      "Partes de este sitio web ofrecen a los usuarios la oportunidad de publicar e intercambiar opiniones e información en determinadas áreas. MapaCHE no filtra, edita, publica ni revisa los comentarios antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista ni las opiniones de MapaCHE, sus agentes y/o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica. En la medida en que lo permitan las leyes aplicables, MapaCHE no será responsable de los comentarios ni de ninguna responsabilidad, daños o gastos causados ​​o sufridos como resultado de cualquier uso o publicación o apariencia de comentarios en este sitio web.",
      "MapaCHE se reserva el derecho de monitorear todos los comentarios y eliminar los que puedan considerarse inapropiados, ofensivos o que incumplan estos Términos y Condiciones.",
      "",
      "Garantizas y declaras que:",
      "Tienes derecho a publicar comentarios en nuestro sitio web y tienes todas las licencias y consentimientos necesarios para hacerlo;",
      "Los comentarios no invaden ningún derecho de propiedad intelectual, incluidos, entre otros, los derechos de autor, patentes o marcas comerciales de terceros;",
      "Los comentarios no contienen ningún material difamatorio, calumnioso, ofensivo, indecente o ilegal de otro modo, que sea una invasión de la privacidad.",
      "Los comentarios no se utilizarán para solicitar o promover negocios o actividades comerciales personalizadas o presentes o actividades ilegales.",
      "Por la presente, otorgas a MapaCHE una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de tus comentarios en todas y cada una de las formas, formatos, o medios.",
      "",
      "Hipervínculos a nuestro contenido:",
      "Las siguientes organizaciones pueden vincularse a nuestro sitio web sin aprobación previa por escrito:",
      "Agencias gubernamentales;",
      "Motores de búsqueda;",
      "Organizaciones de noticias;",
      "Los distribuidores de directorios en línea pueden vincularse a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otras empresas que figuran en la lista; y Empresas acreditadas en todo el sistema, excepto que soliciten organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden hacer hipervínculos a nuestro sitio web.",
      "Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones o a otra información del sitio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos y/o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.",
      "Podemos considerar y aprobar otras solicitudes de enlaces de los siguientes tipos de organizaciones:",
      "fuentes de información de consumidores y/o empresas comúnmente conocidas;",
      "sitios de la comunidad .com;",
      "asociaciones u otros grupos que representan organizaciones benéficas;",
      "distribuidores de directorios en línea;",
      "portales de Internet;",
      "firmas de contabilidad, derecho y consultoría; y instituciones educativas y asociaciones comerciales.",
      "Aprobaremos las solicitudes de enlace de estas organizaciones si: (a) el enlace no nos haría vernos desfavorablemente a nosotros mismos ni a nuestras empresas acreditadas; (b) la organización no tiene registros negativos con nosotros; (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de MapaCHE; y (d) el enlace está en el contexto de información general de recursos.",
      "Estas organizaciones pueden enlazar a nuestra página de inicio siempre que el enlace: (a) no sea engañoso de ninguna manera; (b) no implique falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y (c) encaja en el contexto del sitio de la parte vinculante.",
      "Si eres una de las organizaciones enumeradas en el párrafo 2 y estás interesada en vincularte a nuestro sitio web, debes informarnos enviando un correo electrónico a MapaCHE. Incluye tu nombre, el nombre de tu organización, la información de contacto, así como la URL de tu sitio, una lista de las URL desde las que tienes la intención de vincular a nuestro sitio web y una lista de las URL de nuestro sitio a las que te gustaría acceder. Espera 2-3 semanas para recibir una respuesta.",
      "Las organizaciones aprobadas pueden hacer hipervínculos a nuestro sitio web de la siguiente manera:",
      "Mediante el uso de nuestro nombre corporativo; o",
      "Mediante el uso del localizador uniforme de recursos al que se está vinclando; o",
      "Usar cualquier otra descripción de nuestro sitio web al que está vinculado que tenga sentido dentro del contexto y formato del contenido en el sitio de la parte vinculante.",
      "No se permitirá el uso del logotipo de MapaCHE u otro material gráfico para vincular sin un acuerdo de licencia de marca comercial.",
      "Responsabilidad del contenido:",
      "No seremos responsables de ningún contenido que aparezca en tu sitio web. Aceptas protegernos y defendernos contra todas las reclamaciones que se presenten en tu sitio web. Ningún enlace(s) debe aparecer en ningún sitio web que pueda interpretarse como difamatorio, obsceno o criminal, o que infrinja, de otra manera viole o defienda la infracción u otra violación de los derechos de terceros.",
      "Reserva de derechos:",
      "Nos reservamos el derecho de solicitar que elimines todos los enlaces o cualquier enlace en particular a nuestro sitio web. Apruebas eliminar de inmediato todos los enlaces a nuestro sitio web cuando se solicite. También nos reservamos el derecho de modificar estos términos y condiciones y su política de enlaces en cualquier momento. Al vincular continuamente a nuestro sitio web, aceptas estar vinculado y seguir estos términos y condiciones de vinculación.",
      "Eliminación de enlaces de nuestro sitio web:",
      "Si encuentras algún enlace en nuestro sitio que sea ofensivo por cualquier motivo, puedes contactarnos e informarnos en cualquier momento. Consideraremos las solicitudes para eliminar enlaces, pero no estamos obligados a hacerlo ni a responder directamente.",
      "No nos aseguramos de que la información de este sitio web sea correcta. No garantizamos su integridad o precisión, ni prometemos asegurarnos de que el sitio web permanezca disponible o que el material en el sitio se mantenga actualizado.",
      "",
      "Exención de responsabilidad:",
      "En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este. Nada en este descargo de responsabilidad:",
      "limitará o excluirá nuestra responsabilidad o la tuya por muerte o lesiones personales;",
      "limitará o excluirá nuestra responsabilidad o la tuya por fraude o tergiversación fraudulenta;",
      "limitará cualquiera de nuestras responsabilidades o las tuyas de cualquier manera que no esté permitida por la ley aplicable; o",
      "excluirá cualquiera de nuestras responsabilidades o las tuyas que no puedan estar excluidas según la ley aplicable.",
      "Las limitaciones y prohibiciones de responsabilidad establecidas en esta sección y en otras partes de este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) regirá todas las responsabilidades que surjan en virtud de la exención de responsabilidad, incluidas las responsabilidades que surjan en el contrato, en agravio y por incumplimiento de la obligación legal.",
      "Siempre que el sitio web y la información y los servicios en el sitio se proporcionen de forma gratuita, no seremos responsables de ninguna pérdida o daño de cualquier naturaleza.",
    ],
    version: "Versión",
  },
  ch: {
    add: "添加",
    addname: "輸入名稱",
    addnewaddress: "添加新地址",
    addresses: "地址",
    addSeccion: "添加部分",
    all: "全部",
    allterAsk: "你想開放所有地區嗎？",
    askclean: "您想清除地址標記嗎？",
    cancel: "取消",
    change: "改變",
    changename: "更換名字",
    changenumber: "更改號碼",
    clean: "清理列表",
    close: "關閉",
    colorsList: {
      orange: "橘子",
      blue: "藍色",
      red: "紅色",
      gray: "灰色",
      yellow: "黃色",
      violet: "紫色",
    },
    data: "資料",
    date: "日期",
    day: "天",
    days: "天",
    delete: "刪除",
    deleteActivity: "您想刪除這個活動嗎？",
    deletex: "刪除 X",
    deleteArea: "您想刪除這個定界嗎？",
    delter: "您想刪除這個地區嗎？",
    edit: "編輯",
    email: "電子郵件",
    end: "結束",
    enter: "進入",
    enterEditor: "您以編輯者身份進入，您對領土所做的更改不會記錄在歷史中。",
    enterinfo: "輸入信息",
    event: "事件",
    exit: "註銷",
    export: "匯出",
    exportPDF: "您想匯出為 PDF 文件嗎？",
    flaginstructions: "每行文本都包含一個\n(*)用於觀察每行的名稱",
    groups: "組",
    hour: "時間",
    initCode: "號碼",
    initEnter: "登入",
    initName: "名字",
    initPassword: "密碼",
    accept: "接受",
    lastconnection: "最後連接",
    legalInfo: "法律信息",
    loading: "加載中。。。",
    map: "地圖",
    month: "個月",
    months: "個月",
    monthsname: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    daysName: ["週一", "週二", "週三", "週四", "週五", "週六", "週日"],
    daysNameShort: ["週一", "週二", "週三", "週四", "週五", "週六", "週日"],
    missingField: "缺失字段: ",
    mostrecent: "最近",
    mostoverdue: "最逾期",
    name: "名字",
    networkError: "網路錯誤",
    new: "新",
    newflag: "新標誌",
    newnumber: "新號碼",
    newter: "新地圖",
    noActivities: "沒有活動",
    noresults: "沒有結果",
    notes: "記錄",
    observations: "觀察",
    ok: "好的",
    open: "打開",
    openthispoint: "你想打開這個點嗎？",
    point: "點",
    record: "記錄",
    relocate: "搬遷",
    save: "保存",
    saving: "正在保存。。。",
    scheduled: "預定的",
    search: "搜索",
    start: "開始",
    terAsk: "你想開放這個地區嗎？",
    territories: "地區",
    territory: "地區",
    tileNames: {
      default: "預設設定",
      offline: "離線地圖",
      OSM: "路線圖 (OSM)",
      Road: "路線圖 (Bing)",
      Aerial: "衛星地圖 (Bing)",
      AerialLabel: "衛星地圖和名字 (Bing)",
      Roadmap: "路線圖 (GM)",
      Terrain: "地形",
      Altered_roadmap: "改變路線圖",
      Satellite_only: "衛星地圖",
      Terrain_only: "只有地形",
      Hybrid: "混合地圖",
    },
    time: "時間",
    title: "標題",
    today: "今天",
    update: "更新",
    users: "用戶",
    year: "年",
    years: "年",
    wantclean: "您想清除所有註釋嗎？",
    wantdeleteaddress: "你想刪除這個地址嗎？",
    wantexit: "你想退出嗎？",
    whatInsert: "你想添加什么？",
    wantroute: "您想開通這條航線嗎？",
    startLogin: "開始登入。。。",
    badCredentials: "憑證不正確",
    netWorkError: "連線錯誤",
    upateError: "更新錯誤",
    pleaseSelect: "請選擇：",
    avatar: "頭像",
    conditionsUse: "使用條件",
    conditionsLeyend: [],
    version: "版本",
  },
  chs: {
    add: "添加",
    addname: "输入名称",
    addnewaddress: "添加新地址",
    addresses: "地址",
    addSeccion: "添加部分",
    all: "全部",
    allterAsk: "你想开放所有地区吗？",
    askclean: "您想清除地址标记吗？",
    cancel: "取消",
    change: "改变",
    changename: "更换名字",
    changenumber: "更改号码",
    clean: "清理列表",
    close: "关闭",
    colorsList: {
      orange: "橙子",
      blue: "蓝色",
      red: "红色",
      gray: "灰色",
      yellow: "黄色",
      violet: "紫色",
    },
    data: "资料",
    date: "日期",
    day: "天",
    days: "天",
    delete: "删除",
    deleteActivity: "您想删除这个活动吗？",
    deletex: "删除 X",
    deleteArea: "您想删除这个定界吗？",
    delter: "您想删除这个地区吗？",
    edit: "编辑",
    email: "电子邮件",
    end: "结束",
    enter: "进入",
    enterEditor: "您以编辑者身份进入，您对领土所做的更改不会记录在历史中。",
    enterinfo: "输入信息",
    event: "事件",
    exit: "注销",
    export: "汇出",
    create: "创建",
    exportPDF: "您想汇出为 PDF 文件吗？",
    flaginstructions: "每行文本都包含一个\n(*)用于观察每行的名称",
    groups: "组",
    hour: "时间",
    initCode: "号码",
    initEnter: "登入",
    initName: "名字",
    initPassword: "密码",
    accept: "接受",
    lastconnection: "最后连接",
    legalInfo: "法律信息",
    loading: "加载中。。。",
    month: "个月",
    map: "地图",
    months: "个月",
    monthsname: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
    daysName: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
    daysNameShort: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
    missingField: "缺失字段: ",
    mostrecent: "最近",
    mostoverdue: "最逾期",
    name: "名字",
    networkError: "网路错误",
    new: "新",
    newflag: "新标志",
    newnumber: "新号码",
    newter: "新地图",
    noActivities: "没有活动",
    noresults: "没有结果",
    notes: "记录",
    observations: "观察",
    ok: "好的",
    open: "打开",
    openthispoint: "你想打开这个点吗？",
    point: "点",
    record: "记录",
    relocate: "搬迁",
    save: "保存",
    saving: "正在保存。。。",
    scheduled: "预定的",
    search: "搜索",
    start: "开始",
    terAsk: "你想开放这个地区吗？",
    territories: "地区",
    territory: "地区",
    tileNames: {
      default: "预设设定",
      offline: "离线地图",
      OSM: "路线图 (OSM)",
      Road: "路线图 (Bing)",
      Aerial: "卫星地图 (Bing)",
      AerialLabel: "卫星地图和名字 (Bing)",
      Roadmap: "路线图 (GM)",
      Terrain: "地形",
      Altered_roadmap: "改变路线图",
      Satellite_only: "卫星地图",
      Terrain_only: "只有地形",
      Hybrid: "混合地图",
    },
    time: "时间",
    title: "标题",
    today: "今天",
    update: "更新",
    users: "用户",
    year: "年",
    years: "年",
    wantclean: "您想清除所有注释吗？",
    wantdeleteaddress: "你想删除这个地址吗？",
    wantexit: "你想退出吗？",
    whatInsert: "你想添加什么？",
    wantroute: "您想开通这条航线吗？",
    startLogin: "开始登入。。。",
    badCredentials: "凭证不正确",
    netWorkError: "连线错误",
    upateError: "更新错误",
    pleaseSelect: "请选择：",
    avatar: "头像",
    conditionsUse: "使用条件",
    conditionsLeyend: [],
    version: "版本",
  },
};

export const emptys = [null, undefined, "", false];

export const colors = {
  single: ["red", "yellow"],
  group: ["green", "yellow"],
  done: ["gray", "white"],
};

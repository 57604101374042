import { sendPostServices } from "../services/services";

export const getMainInfo = async (data) => {
  let response = { success: false };
  try {
    //console.log("getMainInfoPayload: ", data);
    response = await sendPostServices(data);
  } catch (error) {
    //console.log("getMainInfo error: ", error);
  }
  return response;
};

export const avatars = {
  ani1: require("../images/avatars/ani1.png"),
  ani2: require("../images/avatars/ani2.png"),
  ani3: require("../images/avatars/ani3.png"),
  ani4: require("../images/avatars/ani4.png"),
  ani5: require("../images/avatars/ani5.png"),
  ani6: require("../images/avatars/ani6.png"),
  ani7: require("../images/avatars/ani7.png"),
  ani8: require("../images/avatars/ani8.png"),
  ani9: require("../images/avatars/ani9.png"),
  ani10: require("../images/avatars/ani10.png"),
  ani11: require("../images/avatars/ani11.png"),
  ani12: require("../images/avatars/ani12.png"),
};

import { sendPostServices } from "../services/services";

export const updateUser = async (ter) => {
  try {
    let updateUserPayload = {
      type: "poster",
      id: localStorage.getItem("id"),
      ter,
    };
    //console.log("updateUserPayload: ", updateUserPayload);
    const response = await sendPostServices(updateUserPayload);
    //console.log(response);
  } catch (error) {
    console.log("updateUser error: ", error);
  }
};

export const updateCheck = async (type, o) => {
  try {
    const { code, value, tile = "" } = o;
    var updateCheckPayload = {
      type,
      code: type === "TERNEW" ? localStorage.getItem("code2") : code,
      value,
      tile,
    };
    //console.log("updateCheckPayload: ", updateCheckPayload);
    const response = await sendPostServices(updateCheckPayload);
    //console.log("response updateCheck: ", response);
    return response;
  } catch (error) {
    console.log("updateCheck error: ", error);
  }
  return false;
};

import { fromLonLat } from "ol/proj";

export const focusLayer = (data, _type, _layer) => {
  let value = false;
  let features = _layer.getSource().getFeatures();

  for (var i = 0; i < features.length && !value; i++) {
    if (Number(features[i].get("id")) === Number(data.id)) {
      value = features[i];
      break;
    }
  }

  if (!value) {
    return value;
  }

  switch (_type) {
    case "update":
      value.getGeometry().setCoordinates(fromLonLat([data.lat, data.lon]));
      return true;
    case "coords":
      return value.getGeometry().getCoordinates();
    default:
      return value;
  }
};

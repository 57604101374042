import { colors, emptys } from "../../data/main";

export const checkAllDone = (_point, _data, _color) => {
  let _max = _data.filter((v) => Number(v.PUNTO) === Number(_point)).length;
  if (
    _max ===
    _data.filter((v) => Number(v.PUNTO) === Number(_point) && Number(v.EST) > 0)
      .length
  ) {
    return colors.done;
  } else if (!emptys.includes(_color)) {
    return [_color, colors.single[1]];
  } else {
    if (_max > 1) {
      return colors.group;
    } else {
      return colors.single;
    }
  }
};

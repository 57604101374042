import { strLang } from "../functions/strLang";

export const checkDate = (theDate) => {
  theDate = theDate.split(" ")[0].split("-");
  switch (localStorage.getItem("lang")) {
    case "esp":
    case "ing":
      theDate.reverse();
      return [
        Number(theDate[0]),
        strLang("monthsname")[Number(theDate[1]) - 1],
        theDate[2],
      ].join(" ");

    case "ch":
    case "chs":
      return [
        theDate[0] + "年",
        Number(theDate[1]) + "月",
        Number(theDate[2]) + "日",
      ].join("");
    default:
      return theDate.reverse().join("/");
  }
};

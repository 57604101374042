import { strLang } from "./strLang";

const name = ["year", "month", "day"];
const names = ["years", "months", "days"];

export const checkTime = (originalDate) => {
  let theDate = new Date(originalDate.split(" ")[0]);
  let red = [];
  let today = new Date();
  red[2] = Math.trunc(
    (new Date(
      [today.getFullYear(), today.getMonth() + 1, today.getDate()].join("-")
    ) -
      theDate.getTime()) /
      (1000 * 3600 * 24)
  );
  if (red[2] <= 0) {
    return strLang("today");
  }
  red[0] = Math.trunc(red[2] / 365);
  red[2] -= red[0] * 365;
  red[1] = Math.trunc(red[2] / 30);
  red[2] -= red[1] * 30;

  red.map((o, index) => {
    o === 1
      ? (red[index] = o + " " + strLang(name[index]))
      : o > 1
      ? (red[index] = o + " " + strLang(names[index]))
      : (red[index] = "");
    return true;
  });

  return red.filter((o) => o !== "").join(", ");
};

// react
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";

import MessageIcon from "@mui/icons-material/Message";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DeleteIcon from "@mui/icons-material/Delete";
import PolylineIcon from "@mui/icons-material/Polyline";
import RoomIcon from "@mui/icons-material/Room";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import NumbersIcon from "@mui/icons-material/Numbers";
import NearMeIcon from "@mui/icons-material/NearMe";
import InputIcon from "@mui/icons-material/Input";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { CircularProgress } from "@mui/material";

import { GithubPicker } from "react-color";

// openlayers
import Map from "ol/Map";
import View from "ol/View";
//import Geolocation from 'ol/Geolocation';
import { Point, Polygon } from "ol/geom";
import { defaults, Modify, Select, Draw } from "ol/interaction";
import Feature from "ol/Feature";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Attribution from "ol/control/Attribution";
import BingMaps from "ol/source/BingMaps";
import { Icon, Style, Stroke, Fill } from "ol/style";
import XYZ from "ol/source/XYZ";
import { transform, fromLonLat } from "ol/proj";

import "../css/main.css";
import flagicon from "./flag.png";
import here from "./arrow.svg";
import compass from "./compass.png";
import lookImg from "./look.png";
import { user, tile, emptys } from "../data/main";
//import { filterKeyLimit } from "./functions";

import { createMarker, newStyle, writeColor } from "./functions/createMarker";
import { strLang } from "../functions/strLang";
import { focusLayer } from "./functions/focusLayer";
import { checkAllDone } from "../TheMap/functions/checkAllDone";

import { useDeviceOrientation } from "./functions/useDeviceOrientation.ts";
import croma from "../images/circle.png";
import { sendPostServices } from "../services/services";
import { getCurrentDate } from "../utilities/getCurrentDate";

import { updateLimits } from "../services/map";
import { createPDFMap } from "../functions/createPDF.js";

var polyGroup = [];
var mainView = false;
//var geolocation = false
var mainAvatar = false;
const herePoint = new Point(fromLonLat([0, 0]));
var tile_layer = false;
var points_layer = false;
var avatars_layer = false;
var area_layer = false;
var arrow_layer = false;
var areaInteraction = false;
var layersInteraction = false;
var drawInteraction = false;
//var x_layer = false;
var mobile = false;
var mainBoxList = false;
var allAddress = [];
var allPoints = [];
var allSections = [];
var allMaps = [];
var terId = false;
var ccc = true;
var editPoly = false;
var dobleClickId = {
  id: false,
  tap: false,
};
var timeoutID = false;
const defRaster = {
  active: false,
  feature: false,
  section: false,
  map: false,
  id: false,
  point: false,
  index: false,
  type: false,
  origin: [0, 0],
  coords: [0, 0],
  nCoords: [0, 0],
  x: 0,
  y: 0,
  newX: false,
};

var keyRaster = { ...defRaster };

var areaRaster = false;

user.avatar = localStorage.getItem("avatar");
user.name = localStorage.getItem("name");
user.id = localStorage.getItem("id");

const reOrder = (data) => {
  return data
    .filter((o) => o.PP === 0)
    .sort((a, b) => (a.PUNTO > b.PUNTO ? 1 : -1))
    .concat(
      data.filter((o) => o.PP > 0).sort((a, b) => (a.PP > b.PP ? 1 : -1))
    );
};

export default function TheMap(props) {
  // set intial state
  const [title, setTitle] = useState("");
  const [dim, setDim] = useState({ main: [100, 100], list: [0, 0] });
  const [activeLook, setActiveLook] = useState(0);
  const [board, setBoard] = useState(0);
  const [pointId, setPointId] = useState({ id: -1, point: false });
  //const [rotation, setRotation] = useState("");
  const [sectionId, setSectionId] = useState(-1);
  //const [activeHeading, setActiveHeading] = useState(0);
  const [activatePolyEdit, setActivatePolyEdit] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const [opencolor, setOpenColor] = useState(false);
  const [PPColor, setPPColor] = useState(false);
  const [limitColor, setLimitColor] = useState("");
  const [openColorPoint, setOpenColorPoint] = useState(0);
  const [infoSection, setInfoSection] = useState(false);
  const [currentTile, setCurrentTile] = useState("");

  const { orientation, requestAccess, revokeAccess } = useDeviceOrientation(); //, gyroError

  const [vmap, setvMap] = useState();
  const [address, setAddress] = useState({ list: [] });
  //onst [featuresLayer, setFeaturesLayer] = useState();
  //const [selectedCoord, setSelectedCoord] = useState();
  const [_width, set_Width] = useState(0);
  //const [dd, setDd] = useState("");
  const stylesLimit = [
    new Style({
      stroke: new Stroke({ color: "rgba(0, 0, 255, 0.3)", width: 3 }),
      fill: new Fill({ color: "rgba(0, 0, 255, 0.05)" }),
    }),
  ];

  // pull refs
  const theMapBox = useRef();
  const mapElement = useRef();
  const leyendRef = useRef();
  const compassRef = useRef();
  const lookRef = useRef();

  const mapRef = useRef();
  mapRef.current = vmap;

  const moveHere = (coords) => {
    arrow_layer.setVisible(true);
    herePoint.setCoordinates(coords);
  };

  const saveLimit = async (forceDelete = false, _new = false) => {
    if (_new) {
      let _feat = area_layer.getSource().getFeatures()[
        area_layer.getSource().getFeatures().length - 1
      ];
      areaRaster = {};
      areaRaster.id = terId;
      areaRaster.feature = _feat;
      areaRaster.feature.set("color", null);
      areaRaster.coords = _feat.getGeometry().getCoordinates();
    }

    let _data = {
      type: "savelimit",
      code: areaRaster.id,
      color: null,
      coords: "",
    };

    if (!forceDelete) {
      let _saveCoords = areaRaster.feature.getGeometry().getCoordinates();
      _saveCoords[0].map((o, index) => {
        _saveCoords[0][index] =
          "[" + transform(o, "EPSG:3857", "EPSG:4326").toString() + "]";
        return true;
      });
      _data.coords = _saveCoords[0].toString();
      _data.color = areaRaster.feature.get("color");
    }

    //console.log(_data);

    const response = await updateLimits(_data);

    //console.log(response);
    if (response && response.status) {
      if (forceDelete) {
        area_layer.getSource().removeFeature(areaRaster.feature);
        areaRaster = false;
        setActivatePolyEdit(false);
        editPoly = false;
        setMoreOptions(false);
        setOpenColor(false);
        setLimitColor("");
      }
    } else {
    }
    if (_new) {
      areaInteraction.setActive(false);
      layersInteraction.setActive(false);
      setActivatePolyEdit(false);
      editPoly = false;
    }
  };

  const getCorrectSource = (data) => {
    if (emptys.includes(data)) {
      data = tile["OSM"];
    }
    return data.provider === "Bing"
      ? new BingMaps({
          key: process.env.REACT_APP_API_BING_KEY,
          crossOrigin: "anonymous",
          imagerySet: data.type,
          // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
        })
      : new XYZ({
          url: data.url,
          attribution:
            "Powered by Esri. " +
            "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
          crossOrigin: "anonymous",
        });
  };

  useEffect(() => {
    //console.log("terId: ", terId);
    tile_layer = new TileLayer({
      source: getCorrectSource(tile[localStorage.getItem("tile")]),
    });

    points_layer = new VectorLayer({
      id: "points_group",
      source: new VectorSource(),
    });

    avatars_layer = new VectorLayer({
      id: "avatars_group",
      source: new VectorSource(),
    });

    area_layer = new VectorLayer({
      id: "area_layer",
      source: new VectorSource({ features: [] }),
      style: stylesLimit,
    });

    const featArrow = new Feature({
      geometry: herePoint,
    });

    featArrow.setStyle(
      new Style({
        image: new Icon({
          anchor: [13, 55],
          anchorXUnits: "pixels",
          anchorYUnits: "pixels",
          src: here,
        }),
      })
    );

    arrow_layer = new VectorLayer({
      id: "arrow",
      zIndex: 1,
      visible: false,
      source: new VectorSource({ features: [featArrow] }),
    });

    mainView = new View({
      projection: "EPSG:3857",
      center: [0, 0],
      //center: fromLonLat([-79.52038, 9.00938]),
      zoom: 13,
      maxZoom: 19,
    });

    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        tile_layer,
        area_layer,
        //hereVector,
        points_layer,
        avatars_layer,
        arrow_layer,
      ],
      view: mainView,
      //controls: [],
      controls: defaults({ attribution: new Attribution() }),
      interactions: defaults({ doubleClickZoom: false }),
    });

    // geolocation = new Geolocation({
    //   tracking: true,
    //   trackingOptions: {
    //     enableHighAccuracy: true,
    //     maximumAge: 2000
    //   },
    //   projection: mainView.getProjection(),
    // });

    // update the HTML page when the position changes.
    /*geolocation.on('change', function () {
      el('accuracy').innerText = geolocation.getAccuracy() + ' [m]';
      el('altitude').innerText = geolocation.getAltitude() + ' [m]';
      el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
      el('heading').innerText = geolocation.getHeading() + ' [rad]';
      el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
    });*/

    // geolocation.on('error', function (error) {
    //   console.log('error', error)
    // });

    /*const accuracyFeature = new Feature();
    geolocation.on('change:accuracyGeometry', function () {
      accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
    });*/

    mainAvatar = createMarker(
      {
        PUNTOS: user["id"],
        name: localStorage.getItem("name"),
        avatar: localStorage.getItem("avatar"),
      },
      "avatar",
      avatars_layer,
      -1,
      false,
      true
    );

    /*geolocation.on('change:position', function () {
      console.log(geolocation.getPosition())
      const coordinates = geolocation.getPosition();
      mainAvatar.setGeometry(coordinates ? new Point(coordinates) : null);
    });*/

    // geolocation.on('change:heading', function () {
    //   /*if (activeHeading>0 && !geolocation.getHeading()) {
    //     setActiveHeading(0)
    //   } else if (activeHeading===0 && geolocation.getHeading()) {
    //     setActiveHeading(1)
    //   }*/
    // });

    // set map onclick handler

    areaInteraction = new Select({
      layers: [area_layer],
    });
    initialMap.addInteraction(areaInteraction);

    areaInteraction.setActive(false);

    layersInteraction = new Modify({
      features: areaInteraction.getFeatures(),
    });
    initialMap.addInteraction(layersInteraction);

    drawInteraction = new Draw({
      source: area_layer.getSource(),
      type: "Polygon",
      finishCondition: (event) => {
        console.log("finish...");
        setTimeout(() => {
          saveLimit(false, true);
        }, 500);
        return true;
      },
    });
    initialMap.addInteraction(drawInteraction);
    drawInteraction.setActive(false);

    areaInteraction.on("select", function (event) {
      console.log("selected");
      if (areaRaster) {
        let compare = [
          areaRaster.feature.getGeometry().getCoordinates()[0],
          areaRaster.coords[0],
        ];
        let _saving = false;
        if (
          compare[0].length != compare[1].length ||
          areaRaster.feature.get("color") != areaRaster.color
        ) {
          _saving = true;
        } else {
          //console.log("diferente: " + compare[0].length, compare[1].length);
          for (let a = 0; a < compare[0].length; a++) {
            if (
              compare[0][a][0] != compare[1][a][0] ||
              compare[0][a][1] != compare[1][a][1]
            ) {
              _saving = true;
              break;
            }
          }
        }

        if (_saving) {
          console.log("start saving");
          if (areaRaster.newColor) {
            changeColor();
          }
          saveLimit();
        }
      }

      if (event.selected.length > 0) {
        //console.log(event.selected[0].get("id"));
        if (event.selected[0].get("name") != title) {
          setTitle(event.selected[0].get("name"));
        }
        writeAreaRaster(event.selected[0]);
      } else {
        setMoreOptions(false);
        setOpenColor(false);
        setLimitColor("");
        setTitle("");
        setTimeout(() => {
          areaRaster = false;
        }, 200);
        console.log("erase");
      }
    });

    initialMap.on("dblclick", handleMapClick);
    initialMap.on("moveend", setMap);
    mainView.on("change:resolution", (event) => {
      console.log("zoom changed");
    });

    mainView.on("change:rotation", () => {
      var grade = Math.round((mainView.getRotation() * 180) / Math.PI);
      compassRef.current.style.transform = "rotate(" + grade + "deg)";
    });

    // save map and vector layer references to state
    setvMap(initialMap);

    window.addEventListener("resize", handleResize);
    //window.addEventListener('deviceorientation', handleOrientation);
    return () => {
      window.removeEventListener("resize", handleResize);
      //window.removeEventListener('deviceorientation', handleOrientation)
    };
  }, []);

  /*function handleOrientation(event) {
    if (orientation && activeLook>1) {
      const alpha = event.alpha;
      const beta = event.beta;
      const gamma = event.gamma;
      mainView.animate({
        rotation: orientation.alpha,
        duration: 500
      });
    }

    // Do stuff...
  }*/

  const SectionsSettings = (_id = false) => {
    if (_id) {
      let subSection = allSections.filter((o) => o.TER === Number(_id));
      setInfoSection({ ...infoSection, id: _id, sections: subSection });
    } else {
      setInfoSection(false);
    }
  };

  const changeTile = (newTile) => {
    if (!emptys.includes(tile[newTile])) {
      setCurrentTile(newTile);
      tile_layer.setSource(getCorrectSource(tile[newTile]));
    }
  };

  useEffect(() => {
    if (emptys.includes(props.data.mapTile)) {
      changeTile(localStorage.getItem("tile"));
    }
  }, [localStorage.getItem("tile")]);

  useEffect(() => {
    if (props.data) {
      //console.log("data: ", props.data);
      terId = props.data.id;
      activateEdition(false, false);

      setTitle(props.data.title);
      //totalAdr = props.data.address
      allAddress = reOrder(props.data.address);
      allPoints = props.data.points;
      allSections = props.data.sections;
      terId === "all" ? SectionsSettings() : SectionsSettings(terId);
      allMaps = props.data.maps;
      //console.log(allPoints);
      //console.log(allPoints.findIndex((p) => p.PUNTOS == "955"));
      setAddress({ list: [] });

      props.data.renew ? openBox2(false) : openBox2(mainBoxList);

      points_layer.getSource().clear();

      if (props.data.limits.length > 0 && props.data.renew) {
        area_layer.getSource().clear();
        polyGroup = [];
        props.data.limits.map((o) => {
          o.LIMITS = new Polygon([JSON.parse(o.LIMITS)])
            .transform("EPSG:4326", "EPSG:3857")
            .getCoordinates();
          if (o.LIMITS[0].length > 0) {
            polyGroup.push({
              id: o.ID,
              name: o.NOM,
              color: o.LCOLOR,
              coords: o.LIMITS,
            });
          }
          return true;
        });

        if (polyGroup.length > 0) {
          //console.log(polyGroup);
          polyGroup.map((o) => {
            let poly = new Feature({
              id: o.id,
              name: o.name,
              color: o.color,
              geometry: new Polygon([o.coords[0]]),
            });

            if (o.color) {
              let _rgb = o.color.split(","); //.map((k) => Number(k));
              if (_rgb.length === 3) {
                let _style = new Style({
                  fill: new Fill({
                    color: `rgba(${_rgb.join(",")}, 0.05)`,
                    weight: 1,
                  }),
                  stroke: new Stroke({
                    color: `rgba(${_rgb.join(",")}, 0.5)`,
                    width: 3,
                  }),
                });
                poly.setStyle(_style);
              }
            }
            area_layer.getSource().addFeature(poly);
            return true;
          });

          mapRef.current.getView().fit(area_layer.getSource().getExtent(), {
            padding: [10, 10, 10, 10],
          });
        }
      }

      allPoints.forEach((o) => {
        o.ADDRS = props.data.address.filter(
          (v) => Number(v.PUNTO) === Number(o.PUNTOS)
        ).length;
        if (Number(o.SEC) === 0) {
          createMarker(o, "flag", points_layer);
        } else {
          o.STATUS = checkAllDone(o.PUNTOS, allAddress, o.COLOR);
          createMarker(o, "point", points_layer, false, false);

          if (o.LAT2 && o.LON2) {
            createMarker(o, "np", points_layer);
          }
        }

        if (props.data.renew) {
          if (
            props.data.centerpoint === "reload" &&
            localStorage.getItem("zoom")
          ) {
            mapRef.current
              .getView()
              .setCenter(JSON.parse(localStorage.getItem("coords")));
            //mapRef.current.getView().setRotation(localStorage.getItem('angle'))
            mapRef.current.getView().setZoom(localStorage.getItem("zoom"));
          } else if (Number(props.data.centerpoint) === Number(o.PUNTOS)) {
            mapRef.current
              .getView()
              .setCenter(
                fromLonLat([o.LAT, o.LON]),
                "EPSG:32643",
                "EPSG:32643"
              );
            mapRef.current.getView().setZoom(19);
            moveHere(fromLonLat([o.LAT, o.LON]));
          } else if (area_layer.getSource().getFeatures().length === 0) {
            mapRef.current.getView().fit(points_layer.getSource().getExtent(), {
              padding: [50, 50, 50, 50],
            });
          }
        }
      });

      if (currentTile != props.data.mapTile) {
        changeTile(
          props.data.mapTile === ""
            ? localStorage.getItem("tile")
            : props.data.mapTile
        );
      }
    }
  }, [props.data]);

  useEffect(() => {
    set_Width(props.width);
  }, [props.width]);

  useEffect(() => {
    //console.log(props.avatars)
    avatars_layer
      .getSource()
      .getFeatures()
      .map((o) => {
        if (
          props.avatars.some((v) => Number(v.ORD) === Number(o.get("id"))) ===
            false &&
          String(o.get("id")) !== String(localStorage.getItem("id"))
        ) {
          avatars_layer.getSource().removeFeature(o);
          //console.log('aaa', o.get('id'), localStorage.getItem('id'))
        } else {
          //console.log('bbb', o.get('id'), localStorage.getItem('id'))
        }
        return true;
      });

    props.avatars.map((o) => {
      if (
        focusLayer(
          { id: o.ORD, lat: o.CX, lon: o.CY },
          "update",
          avatars_layer
        ) === false
      ) {
        createMarker(
          {
            PUNTOS: o.ORD,
            LAT: o.CX,
            LON: o.CY,
            name: o.NOMBRE,
            avatar: o.IMA,
          },
          "avatar",
          avatars_layer
        );
      }
      return true;
    });
  }, [props.avatars]);

  useEffect(() => {
    //console.log(props.gps.longitude, props.gps.latitude);
    if (props.gps.longitude && props.gps.latitude) {
      //mainAvatar.setGeometry(props.gps.longitude && props.gps.latitude ? new Point(fromLonLat([props.gps.longitude, props.gps.latitude])) : null);
      mainAvatar.setGeometry(
        new Point(fromLonLat([props.gps.longitude, props.gps.latitude]))
      );

      if (activeLook > 1) {
        goTo(fromLonLat([props.gps.longitude, props.gps.latitude]));
      }

      //activeHeading===2 && mainView.animate({rotation: geolocation.getHeading(), duration: 500})

      //var fromLatLon = fromLonLat([props.gps.longitude, props.gps.latitude])
      //alert(props.gps.heading)
      /*if (focusLayer({id: localStorage.getItem('id'), lat: props.gps.longitude, lon:props.gps.latitude}, 'update', avatars_layer) === false) {
        createMarker({
          PUNTOS: localStorage.getItem('id'), 
          LAT: props.gps.longitude, 
          LON: props.gps.latitude, 
          name: localStorage.getItem('name'), 
          avatar: localStorage.getItem('avatar')
        }, 'avatar', avatars_layer)
      }*/

      //activeLook===3 && mainView.animate({rotation: props.gps.heading, duration: 500})
      //console.log("act" + activeLook);
      if (activeLook === 0) {
        setActiveLook(1);
      }
    } else {
      //setActiveLook(0);
      //revokeAccess();
    }
  }, [props.gps]);

  useEffect(() => {
    if (orientation && activeLook > 1 && ccc) {
      ccc = false;
      compassRef.current.style.transform =
        "rotate(" + Math.round(orientation.alpha) + "deg)";
      mainView.animate({
        rotation: (Math.round(orientation.alpha) * Math.PI) / 180,
        duration: 500,
      });
      setTimeout(() => {
        ccc = true;
      }, 1000);
    }
  }, [orientation]);

  const handleResize = () => {
    if (theMapBox.current.clientWidth < theMapBox.current.clientHeight) {
      mobile = true;
    } else {
      mobile = false;
    }
    if (mobile) {
      if (leyendRef.current.style.height === "100%") {
        if (leyendRef.current.style.width === "0%") {
          openBox2(false);
        } else {
          openBox2("keep");
        }
      }
    } else {
      if (leyendRef.current.style.width === "100%") {
        if (leyendRef.current.style.height === "0%") {
          openBox2(false);
        } else {
          openBox2("keep");
        }
      }
    }
  };

  const setMap = (event) => {
    if (
      mapRef.current.getView().getZoom() != 13 &&
      mapRef.current
        .getView()
        .getCenter()
        .reduce((a, b) => a - b) != 0
    ) {
      localStorage.setItem("zoom", mapRef.current.getView().getZoom());
      localStorage.setItem(
        "coords",
        JSON.stringify(mapRef.current.getView().getCenter())
      );
      //localStorage.setItem('angle', mapRef.current.getView().getRotation())
    }
    //setActiveLook(1)
    //if (activeLook>1) {setActiveLook(1)}
  };

  const writeRaster = (_feat) => {
    keyRaster["feature"] = _feat;
    keyRaster["id"] = _feat.get("id");
    keyRaster["point"] = _feat.get("point");
    keyRaster["section"] = _feat.get("section");
    keyRaster["map"] = _feat.get("map");
    keyRaster["index"] = allPoints.findIndex(
      (o) => Number(o.PUNTOS) === Number(_feat.get("id"))
    );
    keyRaster["type"] = _feat.get("group");
    keyRaster["coords"] = keyRaster["origin"] = _feat
      .getGeometry()
      .getCoordinates();
  };

  const writeAreaRaster = (_feat) => {
    areaRaster = {};
    areaRaster["id"] = _feat.get("id");
    areaRaster["color"] = _feat.get("color");
    areaRaster["newColor"] = false;
    areaRaster["feature"] = _feat;
    areaRaster["coords"] = _feat.getGeometry().getCoordinates();
    setMoreOptions(true);
  };

  const focusPoint = (temp_feature) => {
    if (temp_feature) {
      closeGPS();
      writeRaster(temp_feature);
      goTo(temp_feature.getGeometry().getCoordinates(), true);

      switch (temp_feature.get("group")) {
        case "flag":
        case "point":
          openBox2(temp_feature.get("id"));
          break;
        default:
          break;
      }

      //console.log(keyRaster);
    } else {
      //console.log(temp_feature);
    }
  };

  // map click handler
  const handleMapClick = (event) => {
    const _coords = [...event.coordinate];
    const temp_feature = mapRef.current.forEachFeatureAtPixel(
      event.pixel,
      (feature, layer) => {
        return feature;
      }
    );

    if (editPoly) {
      //console.log(temp_feature);
    } else if (
      temp_feature &&
      ["point", "flag"].includes(temp_feature.get("group"))
    ) {
      focusPoint(temp_feature);
    } else {
      // console.log("rrrrrr");
      // console.log(editPoly, activatePolyEdit);

      closeGPS();

      let _maps = `<select id='swal-list' class='swal2-select'>${writeMapsList()}</select>`;
      let _textarea = `<textarea id='swal-textarea' class='swal2-textarea' style="width:80%; resize: none;" placeholder="${strLang(
        "flaginstructions"
      )}"></textarea>`;

      setTimeout(() => {
        Swal.fire({
          title: strLang("newflag"),
          html: terId === "all" ? _maps + _textarea : _textarea,
          showCancelButton: true,
          confirmButtonText: strLang("save"),
          confirmButtonColor: "#5091FF",
          cancelButtonText: strLang("cancel"),
          preConfirm: (event) => {
            console.log("preconfirm");
            //console.log(event);
            return {
              map:
                terId === "all"
                  ? document.getElementById("swal-list").value
                  : false,
              text: document.getElementById("swal-textarea").value,
            };
          },
          onOpen: () => Swal.getConfirmButton().focus(),
        }).then((result) => {
          //console.log(result);
          if (result.isConfirmed) {
            if (result.value.text.trim() === "") {
              Swal.fire({
                icon: "error",
                title: strLang("enterinfo"),
              });
            } else {
              Swal.fire({
                title: strLang("saving"),
                inputValidator: (value) => {
                  //console.log(value);
                },
                didOpen: () => {
                  Swal.showLoading();
                },
                willClose: () => {
                  //clearInterval(timerInterval)
                },
              });
              var coords2 = transform(_coords, "EPSG:3857", "EPSG:4326");
              openBox2(false);
              updateCheck("flag", {
                ter: terId === "all" ? result.value.map : terId,
                lat: coords2[0],
                lon: coords2[1],
                obs: result.value.text.trim(),
                map: terId === "all" ? result.value.map : terId,
                coords: _coords,
              });
            }
          }
        });
      }, 250);
    }

    /*
    const clickedCoord = mapRef.current.getCoordinateFromPixel(event.pixel);
    //alert(clickedCoord)
    // transform coord to EPSG 4326 standard Lat Long
    const transormedCoord = transform(clickedCoord, 'EPSG:3857', 'EPSG:4326')
    //vmap.getView().setCenter(clickedCoord, 'EPSG:32643', 'EPSG:32643');
    // set React state
    setSelectedCoord( transormedCoord )
    //mapRef.current.getView().setCenter(fromLonLat(transormedCoord), 'EPSG:32643', 'EPSG:32643');
    createMarker({LAT:transormedCoord[0], LON:transormedCoord[1]}, 'flag')
    */
  };

  const goTo = (value, _arrow) => {
    if (!value) {
      return value;
    }
    _arrow && moveHere(value);
    mainView.animate({ center: value, duration: 500 });
  };

  const openWindow = (index, o) => {
    //console.log(o);
    if (Number(localStorage.getItem("rol") > 0)) {
      Swal.fire({
        title: o.NOM,
        html:
          `<input id="swal-input1" placeholder="` +
          strLang("name") +
          `" class="swal2-input" style="margin:0px; margin-bottom:10px" value="${o.NOM}">` +
          `<input id="swal-input2" placeholder="` +
          strLang("observations") +
          `" class="swal2-input" style="margin:0px" value="${o.OBSER}">` +
          `<textarea id="swal-input3" placeholder="` +
          strLang("notes") +
          `" class="swal2-textarea">${o.NOTAS}</textarea>`,
        showCancelButton: true,
        confirmButtonText: strLang("update"),
        confirmButtonColor: "#5091FF",
        showDenyButton: true,
        denyButtonText: strLang("delete"),
        denyButtonColor: "red",
        cancelButtonText: strLang("cancel"),
        preConfirm: () => {
          return [
            document.getElementById("swal-input1").value,
            document.getElementById("swal-input2").value,
            document.getElementById("swal-input3").value,
          ];
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(result.value);
          // console.log(index);
          // console.log(o);
          updateCheck("UPDATE", {
            index: index,
            id: o.ID,
            name: result.value[0].trim(),
            obs: result.value[1].trim(),
            notes: result.value[2].trim(),
          });
        }
        // && updateCheck('UPDATE', {index: index, data: o, name: result.value.trim(), obs: , notes: })
        result.isDenied &&
          Swal.fire({
            title: strLang("wantdeleteaddress"),
            confirmButtonText: strLang("delete"),
            confirmButtonColor: "red",
            showCancelButton: true,
            cancelButtonText: strLang("cancel"),
          }).then((result) => {
            if (result.isConfirmed) {
              updateCheck("DELETE", { index: index, data: o, value: o.ID });
            }
          });
      });
    } else {
      Swal.fire({
        title: o.NOM,
        input: "textarea",
        inputValue: o.NOTAS,
        showCancelButton: true,
        confirmButtonText: strLang("save"),
        confirmButtonColor: "#5091FF",
        cancelButtonText: strLang("cancel"),
      }).then((result) => {
        result.isConfirmed &&
          updateCheck("NOTAS", {
            index: index,
            data: o,
            value: result.value.trim(),
          });
      });
    }
  };

  const updateCheck = async (type, o) => {
    let tempAddr = address.list;

    if (type === "EST") {
      if (Number(o.data.EST) === Number(o.value)) {
        o.value = 0;
      }
      tempAddr[o.index][type] = -1;
      setAddress({ list: tempAddr });
    }

    var tvar = { type, date: getCurrentDate(true) };
    switch (type) {
      case "ADDR":
        o.index = allAddress.filter(
          (o) => Number(o.PUNTO) === pointId.id
        ).length;
        tvar = {
          ...tvar,
          code: keyRaster["feature"].get("id"),
          number: keyRaster["feature"].get("point"),
          order: o.index,
          name: o.name,
          obs: o.obs,
          map: keyRaster["feature"].get("map"),
        };
        break;
      case "DELETE":
        tvar = {
          ...tvar,
          code: o.data.ID,
          point: o.data.PUNTO,
          number: o.data.PP,
          map: terId,
        };
        break;
      case "REORDER":
        //console.log(o);
        tvar = {
          ...tvar,
          group: keyRaster["type"],
          code: o.code,
          number_old: o.number_old,
          number_new: o.number_new,
          map_old: o.map_old,
          map_new: o.map_new,
        };
        break;
      case "CLEAN":
        tvar = {
          ...tvar,
          map: terId,
        };
        break;
      case "ADDSECTION":
      case "COLOR":
        tvar = { ...o, ...tvar };
        break;
      case "SECTION":
        tvar = {
          ...tvar,
          code: keyRaster["id"],
          section: o.section,
          map: keyRaster["feature"].get("map"),
        };
        break;
      case "UPDATE":
        tvar = {
          ...tvar,
          code: o.id,
          name: o.name,
          obs: o.obs,
          notes: o.notes,
          map: terId,
        };
        break;
      case "np":
        tvar = {
          ...tvar,
          code: keyRaster["id"],
          lat: o.lat,
          lon: o.lon,
          delete: o.delete,
          map: keyRaster["feature"].get("map"),
        };
        break;
      case "flag":
        tvar = {
          ...tvar,
          lat: o.lat,
          lon: o.lon,
          obs: o.obs,
          map: o.map,
        };
        break;
      default:
        tvar = {
          ...tvar,
          code: o.data.ID,
          value: o.value,
          map: terId,
          user: localStorage.getItem("name"),
          rol: localStorage.getItem("rol"),
        };
        break;
    }

    //console.log("tvar", tvar);
    const response = await sendPostServices(tvar);

    //console.log("response", response);
    if (response.status) {
      user.date = response.date;
      switch (type) {
        case "ADDR":
          allAddress.push({
            EST: 0,
            ID: response.code,
            NOM: o.name,
            NOTAS: "",
            OBSER: o.obs,
            PUNTO: keyRaster["feature"].get("id"),
            PP: keyRaster["feature"].get("point"),
          });
          allAddress = reOrder(allAddress);

          tempAddr.push({
            EST: 0,
            ID: response.code,
            NOM: o.name,
            NOTAS: "",
            OBSER: o.obs,
            PUNTO: keyRaster["feature"].get("id"),
            SEC: keyRaster["feature"].get("point"),
          });
          break;
        case "DELETE":
          var thePoint = tempAddr[o.index].PUNTO;
          var theNumber = tempAddr[o.index].PP;
          var theId = tempAddr[o.index].ID;
          tempAddr.splice(o.index, 1);
          allAddress = allAddress.filter((o) => o.ID != theId);
          let _feat0 = getFeat(thePoint, points_layer);

          if (response.deletedpoint) {
            let _typefeat = _feat0.get("group");
            if (_typefeat === "point") {
              let _flag = getFeat("np_" + _feat0.get("id"), points_layer);
              if (_flag) {
                points_layer.getSource().removeFeature(_flag);
              }
            }

            points_layer.getSource().removeFeature(_feat0);
            if (_typefeat === "point") {
              moveNumbers(
                allPoints.findIndex((p) => p.SEC == theNumber),
                -1
              );
            }
            allPoints = allPoints.filter((o) => o.PUNTOS != thePoint);
            if (pointId.id != -1) {
              openBox2(false);
            }
          } else if (_feat0.get("group") === "point") {
            _feat0.setStyle(
              newStyle(
                theNumber,
                checkAllDone(thePoint, tempAddr, _feat0.get("color")[0])
              )
            );
          }
          allPoints.sort((a, b) => (a.SEC > b.SEC ? 1 : -1));
          allAddress = reOrder(allAddress);
          break;
        case "REORDER":
          let _featExists = true;
          if (o.map_old != o.map_new && terId > 0) {
            points_layer.getSource().removeFeature(keyRaster["feature"]);
            let _np = getFeat("np_" + keyRaster["id"], points_layer);
            if (_np) {
              points_layer.getSource().removeFeature(_np);
            }
            allAddress = allAddress.filter((p) => p.PUNTO != keyRaster["id"]);
            allPoints = allPoints.filter((p) => p.PUNTOS != keyRaster["id"]);
            _featExists = false;
          }

          /*if (keyRaster["type"] === "point") {
            if (_featExists) {
              keyRaster["feature"].set("point", 0);
              keyRaster["feature"].set("map", 0);
              keyRaster["point"] = 0;
              keyRaster["map"] = 0;
              allPoints[keyRaster["index"]].SEC = 0;
              allPoints[keyRaster["index"]].TER = 0;
            }
            arrangeNumbers(keyRaster["point"], keyRaster["map"], -1);
          }*/

          if (
            (terId === "all" || Number(o.map_old) === Number(o.map_new)) &&
            _featExists
          ) {
            keyRaster["feature"].set("point", 0);
            keyRaster["feature"].set("map", 0);
            allPoints[keyRaster["index"]].SEC = 0;
            allPoints[keyRaster["index"]].TER = 0;

            /*if (keyRaster["type"] === "point") {
              arrangeNumbers(
                keyRaster["point"],
                keyRaster["map"],
                -1,
                response.list
              );
            }*/

            keyRaster["point"] = 0;
            keyRaster["map"] = 0;

            //arrangeNumbers(o.number_new - 1, o.map_new, 1, response.list);
            allPoints[keyRaster["index"]].STATUS = checkAllDone(
              keyRaster["id"],
              allAddress
            );
            allPoints[keyRaster["index"]].SEC = o.number_new;
            allPoints[keyRaster["index"]].TER = o.map_new;
            updateAddList(keyRaster["id"], o.number_new);

            if (keyRaster["type"] === "flag") {
              points_layer.getSource().removeFeature(keyRaster["feature"]);
              createMarker(
                allPoints[keyRaster["index"]],
                "point",
                points_layer,
                false,
                false
              );
            } else {
              keyRaster["feature"].set("point", o.number_new);
              keyRaster["feature"].set("map", o.map_new);
              keyRaster["feature"].setStyle(
                newStyle(o.number_new, allPoints[keyRaster["index"]].STATUS)
              );
            }
            arrangeNumbers(o.number_new - 1, o.map_new, 1, response.list);
            allPoints.sort((a, b) => (a.SEC > b.SEC ? 1 : -1));
            allAddress = reOrder(allAddress);
          }
          clearRaster();
          openBox2(false);
          break;
        case "CLEAN":
          allAddress.map((o) => {
            o.EST = 0;
            return true;
          });
          points_layer
            .getSource()
            .getFeatures()
            .forEach((c) => {
              if (c.get("group") === "point") {
                //console.log(c.get("point"), c.get("color"));
                let thecolor = checkAllDone(
                  c.get("id"),
                  allAddress,
                  c.get("color")[0]
                );
                c.setStyle(newStyle(c.get("point"), thecolor));
              }
            });
          if (pointId.id === -1) {
            openBox2("all");
          }
          break;
        case "ADDSECTION":
        case "COLOR":
          break;
        case "SECTION":
          keyRaster["feature"].set("section", Number(o.section));
          for (let z = 0; z < allPoints.length; z++) {
            if (allPoints[z].PUNTOS == keyRaster["id"]) {
              allPoints[z].SECTION = Number(o.section);
              break;
            }
          }
          break;
        case "UPDATE":
          let idx = allAddress.findIndex((p) => p.ID == o.id);
          allAddress[idx].NOM = tempAddr[o.index].NOM = o.name;
          allAddress[idx].OBSER = tempAddr[o.index].OBSER = o.obs;
          allAddress[idx].NOTAS = tempAddr[o.index].NOTAS = o.notes;
          break;
        case "np":
          if (response.code) {
            user.date = response.date;
            let _feat = false;
            if (keyRaster.nCoords[0] === "del") {
              //console.log(keyRaster["feature"].get("id"));
              points_layer
                .getSource()
                .removeFeature(
                  getFeat(keyRaster["feature"].get("id"), points_layer)
                );
              _feat = keyRaster["feature"];

              let idx = allPoints.findIndex(
                (p) => p.PUNTOS === Number(keyRaster["feature"].get("id"))
              );
              //console.log(idx);
              allPoints[idx].LAT2 = null;
              allPoints[idx].LON2 = null;
            } else if (o.delete) {
              let _np = getFeat(
                "np_" + keyRaster["feature"].get("id"),
                points_layer
              );
              if (_np) {
                //console.log(keyRaster["feature"].get("id"));
                let idx = allPoints.findIndex(
                  (p) => p.PUNTOS === Number(keyRaster["feature"].get("id"))
                );
                //console.log(idx);
                allPoints[idx].LAT2 = null;
                allPoints[idx].LON2 = null;
                points_layer.getSource().removeFeature(_np);
              }
              keyRaster["feature"]
                .getGeometry()
                .setCoordinates(keyRaster["coords"]);
              mapRef.current.getView().setCenter(keyRaster["coords"]);
              _feat = keyRaster["feature"];
            }
            if (_feat) {
              writeRaster(_feat);
              setPointId({
                id: Number(_feat.get("id")),
                point: Number(_feat.get("point")),
              });
            }
          }
          break;
        case "flag":
          if (response.code) {
            user.date = response.date;
            createMarker(
              { ...response.point[0] },
              "flag",
              points_layer,
              o.coords
            );
            response.address.map((o) => allAddress.push(o));
            response.point.map((o) => allPoints.push(o));
            allPoints.sort((a, b) => (a.SEC > b.SEC ? 1 : -1));
            allAddress = reOrder(allAddress);
          }
          break;
        default:
          tempAddr[o.index][type] = response[type][0][type];
          let a_feat = getFeat(tempAddr[o.index].PUNTO, points_layer);
          if (a_feat && a_feat.get("group") === "point") {
            a_feat.setStyle(
              newStyle(
                tempAddr[o.index].PP,
                checkAllDone(
                  tempAddr[o.index].PUNTO,
                  tempAddr,
                  a_feat.get("color")[0]
                )
              )
            );
          }
          break;
      }

      Swal.close();
      if (!["ADDSECTION", "COLOR"].includes(type)) {
        console.log("passs");
        setAddress({ list: [...tempAddr] });
        props.updateMap(terId, allPoints, allAddress);
      }
    } else if (response.netWorkError) {
      Swal.fire({ icon: "error", title: strLang("netWorkError") });
    } else {
      Swal.fire({ icon: "error", title: strLang("upateError") });
    }
  };

  const arrangeNumbers = (_point, _map, _increase, realList) => {
    var tempIndex;
    realList.forEach((h, index) => {
      tempIndex = allPoints.findIndex((p) => p.PUNTOS == h.PUNTOS);
      if (tempIndex > -1) {
        allPoints[tempIndex].SEC = Number(h.SEC);
        updateAddList(allPoints[tempIndex].PUNTOS, allPoints[tempIndex].SEC);
        let _feat = getFeat(allPoints[tempIndex].PUNTOS, points_layer);
        if (_feat) {
          _feat.set("point", allPoints[tempIndex].SEC);
          _feat.setStyle(
            newStyle(
              allPoints[tempIndex].SEC,
              checkAllDone(
                allPoints[tempIndex].PUNTOS,
                allAddress,
                _feat.get("color")[0]
              )
            )
          );
        }

        _feat = getFeat("np_" + allPoints[tempIndex].PUNTOS, points_layer);
        if (_feat) {
          points_layer.getSource().removeFeature(_feat);
          createMarker(allPoints[tempIndex], "np", points_layer);
        }
      }
    });

    /*allPoints.map((o, index) => {
      if (
        allPoints[index].SEC > _point &&
        Number(allPoints[index].TER) === Number(_map)
      ) {
        allPoints[index].SEC = Number(allPoints[index].SEC) + _increase;
        updateAddList(allPoints[index].PUNTOS, allPoints[index].SEC);
        let _feat = getFeat(allPoints[index].PUNTOS, points_layer);
        if (_feat) {
          _feat.set("point", allPoints[index].SEC);
          _feat.setStyle(
            newStyle(
              allPoints[index].SEC,
              checkAllDone(
                allPoints[index].PUNTOS,
                allAddress,
                _feat.get("color")[0]
              )
            )
          );
        }

        _feat = getFeat("np_" + allPoints[index].PUNTOS, points_layer);
        if (_feat) {
          points_layer.getSource().removeFeature(_feat);
          createMarker(allPoints[index], "np", points_layer);
        }
      }
      return true;
    });*/
    return true;
  };

  const updateAddList = (_id, _value) => {
    allAddress.forEach((h, index) => {
      if (h.PUNTO == _id) {
        allAddress[index]["PP"] = _value;
      }
    });
    return true;
  };

  const moveNumbers = (_number, _value) => {
    _number = Number(_number);
    if (_number > -1) {
      for (var w = _number; w < allPoints.length; w++) {
        if (
          allPoints[w].PUNTOS != keyRaster["id"] &&
          keyRaster["map"] == allPoints[w].TER
        ) {
          allPoints[w].SEC += _value;

          updateAddList(allPoints[w].PUNTOS, allPoints[w].SEC);

          let _np = getFeat(allPoints[w].PUNTOS, points_layer);
          if (_np) {
            _np.set("point", allPoints[w].SEC);
            _np.setStyle(
              newStyle(
                allPoints[w].SEC,
                checkAllDone(
                  allPoints[w].PUNTOS,
                  allAddress,
                  _np.get("color")[0]
                )
              )
            );
          }

          _np = getFeat("np_" + allPoints[w].PUNTOS, points_layer);
          if (_np) {
            points_layer.getSource().removeFeature(_np);
            createMarker(allPoints[w], "np", points_layer);
          }
        }
      }
    }
    // points_layer.getSource().getFeatures().forEach(async(c) => {
    //   if (c.get('group')==='point' && Number(c.get('point'))>_axis && c.get('id')!=keyRaster.id) {

    //     let cvalue = c.get('point')+_value

    //     updateAddList(c.get('id'), cvalue, true)

    //     c.set('point', cvalue)
    //     c.setStyle(newStyle(cvalue, checkAllDone(c.get('id'), allAddress, c.get('color'))))

    //     let _np = getFeat('np_'+c.get('id'))
    //     if (_np) {
    //       points_layer.getSource().removeFeature(_np)
    //       console.log('aaaaS')
    //       console.log(allPoints[_index])
    //       createMarker(allPoints[_index], 'np', points_layer)
    //     }

    //   }
    // })

    return true;
  };

  const openBox2 = async (_open) => {
    mainBoxList = _open;
    let tDim = false;
    if (theMapBox.current.clientWidth < theMapBox.current.clientHeight) {
      mobile = true;
    } else {
      mobile = false;
    }
    if (_open) {
      switch (_open) {
        case "all":
          setPointId({ id: -1, point: false });
          if (props.data) {
            allAddress.length > 0 && setAddress({ list: allAddress });
          }
          break;
        case "keep":
          break;
        default:
          if (getFeat("np_" + keyRaster["feature"].get("id"), points_layer)) {
            //console.log("rol " + Number(localStorage.getItem("rol")));
            setPointId({ id: "delete", point: keyRaster["point"] });
          } else {
            setPointId({ id: _open, point: keyRaster["point"] });
          }
          keyRaster["feature"].get("color")
            ? setPPColor(keyRaster["feature"].get("color")[0])
            : setPPColor(false);
          setAddress({
            list: allAddress.filter((o) => Number(o.PUNTO) === Number(_open)),
          });
          break;
      }
      mobile
        ? (tDim = { main: [100, 70], list: [100, 30] })
        : (tDim = { main: [70, 100], list: [30, 100] });
    } else {
      //keyRaster = {...defRaster}
      if (props.data) {
        props.data.address.length > 0 && setAddress({ list: [] });
      }
      mobile
        ? (tDim = { main: [100, 100], list: [100, 0] })
        : (tDim = { main: [100, 100], list: [0, 100] });
    }
    setDim(tDim);
    /*mapElement.current.style.width=dim.box1[0]+'%'
    mapElement.current.style.height=dim.box1[1]+'%'

    leyendRef.current.style.width=dim.box2[0]+'%'
    leyendRef.current.style.height=dim.box2[1]+'%'*/
  };

  const closeGPS = () => {
    //console.log(props.gps.longitude, props.gps.latitude);
    //console.log(activeLook);
    //if (activeLook) {
    //console.log("close");
    setActiveLook(1);
    revokeAccess();
    //}
  };

  const handleClickAddr = (index, firstTap, secondTap) => {
    if (dobleClickId.id != index) {
      if (firstTap) {
        firstTap();
      }
      dobleClickId.id = index;
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      timeoutID = setTimeout(() => {
        dobleClickId.id = false;
      }, 250);
    } else {
      secondTap();
      dobleClickId.id = false;
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    }
  };

  function LineAddress(props) {
    var o = props.data;
    let vv = o.PP;
    var _tpoint = getFeat(o.PUNTO, points_layer);
    props.index > 0 &&
    Number(address.list[props.index - 1].PUNTO) === Number(o.PUNTO)
      ? (vv = "")
      : vv === 0
      ? (vv = (
          <div
            className="listpoint prevent-select"
            onClick={() => {
              handleClickAddr("p" + props.index, false, () => {
                focusPoint(_tpoint);
              });
            }}
            style={{ border: "none" }}
          >
            <img
              src={flagicon}
              alt="Logo"
              style={{ height: 30, marginLeft: 10, marginTop: 5 }}
            />
          </div>
        ))
      : (vv = (
          <div
            className="listpoint prevent-select"
            onClick={() => {
              handleClickAddr("p" + props.index, false, () => {
                focusPoint(_tpoint);
              });
            }}
            style={{
              border:
                _tpoint && _tpoint.get("section") > 0
                  ? "solid 2px blue"
                  : "solid 2px red",
            }}
          >
            {o.PP}
          </div>
        ));

    //o.EST>1?esta--:<></>
    //o.NOTAS!==''?esta--:<></>
    //o.EST===3?<></>:<></>
    return (
      <>
        {vv != "" && (
          <tr>
            <td>&nbsp;</td>
          </tr>
        )}
        <tr valign="top">
          <td style={{ position: "relative", width: 40 }}>{vv}</td>
          <td style={{ width: 30 }}>
            {Number(o.EST) === 0 ? (
              <CheckBoxOutlineBlankIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  updateCheck("EST", { index: props.index, data: o, value: 1 });
                }}
              />
            ) : Number(o.EST) === -1 ? (
              <CircularProgress size={24} />
            ) : (
              <CheckBoxIcon
                style={{ color: "#289315", cursor: "pointer" }}
                onClick={() => {
                  updateCheck("EST", { index: props.index, data: o, value: 0 });
                }}
              />
            )}
          </td>
          <td
            style={{ textAlign: "left", fontSize: 20, flex: 1 }}
            onClick={
              () => {
                handleClickAddr(
                  "e" + props.index,
                  () => {
                    closeGPS();
                    goTo(
                      focusLayer({ id: o.PUNTO }, "coords", points_layer),
                      true
                    );
                  },
                  () => {
                    openWindow(props.index, o);
                  }
                );
              } //;
              //closeGPS();
              //goTo(focusLayer({ id: o.PUNTO }, "coords", points_layer), true);
            }
            // onDoubleClick={(e) => {
            //   openWindow(props.index, o);
            // }}
          >
            {o.NOTAS !== "" && (
              <span style={{ position: "relative", height: 4 }}>
                <MessageIcon
                  style={{
                    position: "absolute",
                    color: "#FF5900",
                    fontSize: 20,
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            )}
            {Number(o.EST) === 3 ? (
              <span style={{ textDecoration: "line-through" }}>{o.NOM}</span>
            ) : (
              o.NOM
            )}
            {o.OBSER !== "" ? (
              <p style={{ margin: 0, fontSize: 14 }}>{"(" + o.OBSER + ")"}</p>
            ) : (
              <></>
            )}
          </td>
          <td style={{ whiteSpace: "nowrap", width: 50 }}>
            {/* <PersonOffIcon style={{color:o.BLO?'#FF5900':'gray'}} onClick={()=>{noVisit({index:props.index, data:o, value:2})} }/> */}
            <PersonOffIcon
              style={{
                color: Number(o.EST) === 2 ? "#FF5900" : "gray",
                cursor: "pointer",
              }}
              onClick={() => {
                updateCheck("EST", { index: props.index, data: o, value: 2 });
              }}
            />
            <DeleteIcon
              style={{
                color: Number(o.EST) === 3 ? "red" : "gray",
                cursor: "pointer",
              }}
              onClick={() => {
                updateCheck("EST", { index: props.index, data: o, value: 3 });
              }}
            />
          </td>

          {/*
        <Grid item xs={1}><EditIcon onClick={()=>{openWindow(o)}} style={{color:'#FF5900'}} /></Grid>

          
        o.NOTAS!==''?<Grid item xs={1}><MessageIcon style={{color:'#FF5900'}} /></Grid>:<></>
        o.EST===2?<Grid item xs={1}><PersonOffIcon /></Grid>:<></>
        o.EST===4?<Grid item xs={1}><BlockIcon /></Grid>:<></>
        o.EST===3?<Grid item xs={1}><DeleteIcon style={{color:'red'}} /></Grid>:<></>*/}
        </tr>
      </>
    );
  }

  /*const noVisit = (data) => {
    updateCheck("BLO", data);
  };*/

  const getFeat = (_id, _layer) => {
    let _value = false;
    let nlayers = _layer.getSource().getFeatures();
    for (var i = 0; i < nlayers.length; i++) {
      if (String(nlayers[i].get("id")) === String(_id)) {
        _value = nlayers[i];
        break;
      }
    }

    return _value;
  };

  const beginPosition = (_new) => {
    openBox2(false);
    setBoard(100);
    arrow_layer.setVisible(false);
    if (
      keyRaster["type"] === "point" &&
      Number(localStorage.getItem("rol")) === 0 &&
      _new
    ) {
      let exist_x = getFeat(
        "np_" + keyRaster["feature"].get("id"),
        points_layer
      );

      if (exist_x) {
        points_layer.getSource().removeFeature(exist_x);
      } else {
        keyRaster["newX"] = true;
      }

      createMarker(
        {
          COORDS: keyRaster["coords"],
          PUNTOS: keyRaster["feature"].get("id"),
          OBSER: "",
          SEC: keyRaster["feature"].get("point"),
        },
        "np",
        points_layer,
        false,
        writeRaster
      );
    }
    //console.log(keyRaster);
    mapRef.current
      .getView()
      .animate({ center: keyRaster["coords"], duration: 500, zoom: 19 });
  };

  const writeMapsList = (_id = false) => {
    var mapList = "";
    allMaps.map((o) => {
      mapList += `<option value=${o.ID} ${
        Number(o.ID) === Number(_id) ? "selected" : ""
      } >${o.NOM} (${o.TOTAL})</option>`;
      return true;
    });
    return mapList;
  };

  const addNumber = async () => {
    var numberslist = "";
    var mapList = writeMapsList(keyRaster["feature"].get("map"));

    let t_title = keyRaster["type"] === "flag" ? "newnumber" : "changenumber";

    let lastPoint = await sendPostServices({
      type: "mappoint",
      code: keyRaster["feature"].get("map"),
    }).then((data) => data.points[0].SEC);
    /*fetch(process.env.REACT_APP_API_URL, {
      method: "POST",
      body: JSON.stringify({
        type: "mappoint",
        code: keyRaster["feature"].get("map"),
      }),
      headers: { "Content-type": "application/x-www-form-urlencoded" },
    })
      .then((response) => response.json())
      .then((data) => data.points[0].SEC);*/
    for (let a = 1; a <= lastPoint; a++) {
      numberslist += `<option value=${a} ${
        a == keyRaster["feature"].get("point") ? "selected" : ""
      } >${a}</option>`;
    }

    if (keyRaster["type"] === "flag") {
      numberslist += `<option value=${lastPoint + 1} selected >${
        lastPoint + 1
      }</option>`;
    }

    const { value: gdata } = await Swal.fire({
      title: strLang(t_title),
      html:
        `<input id='flag' type='hidden' value=${
          keyRaster["type"] === "flag" ? 1 : 0
        } />` +
        `<input id='mainmap' type='hidden' value=${keyRaster["feature"].get(
          "map"
        )} />` +
        `<select id='swal-select2' onchange='` +
        `document.getElementById("swal-select1").innerHTML=""; fetch("${
          process.env.REACT_APP_API_URL
        }", { method: "POST", body: JSON.stringify({ type: "mappoint", code: this.value }), headers: { "Content-type": "application/x-www-form-urlencoded" },}).then((response) => response.json()).then((data) => {let box = ""; let _plus=0; if (${
          keyRaster["type"] === "flag" ? false : true
        } && this.value==${keyRaster["feature"].get(
          "map"
        )}) { _plus=1;} if (data.points.length>0) {for (a = 1; a <= data.points[0].SEC+1-_plus; a++) {let _sel = ""; if (a==data.points[0].SEC+1) {_sel="selected"} box += "<option value=" + a + " "+_sel+">" + a + "</option>";}} else {box = "<option value=1>1</option>";}  document.getElementById("swal-select1").innerHTML = box;});' class='swal2-select'>${mapList}</select>` +
        `<select id='swal-select1' class='swal2-select'>${numberslist}</select>`,
      focusConfirm: false,
      confirmButtonText: strLang("change"),
      showCancelButton: true,
      cancelButtonText: strLang("cancel"),
      didOpen: () => {
        console.log(Swal.getHtmlContainer().children[3].focus());
      },
      preConfirm: () => {
        return {
          point: document.getElementById("swal-select1").value,
          map: document.getElementById("swal-select2").value,
        };
      },
    });

    if (
      gdata &&
      (keyRaster["feature"].get("map") != gdata.map ||
        keyRaster["feature"].get("point") != gdata.point)
    ) {
      updateCheck("REORDER", {
        index: 0,
        code: keyRaster["feature"].get("id"),
        number_old: keyRaster["feature"].get("point"),
        number_new: gdata.point,
        map_old: keyRaster["feature"].get("map"),
        map_new: gdata.map,
      });
    }
  };

  const beginRaster = (event) => {
    keyRaster["active"] = true;
    if (event.touches) {
      keyRaster["x"] = event.touches[0].clientX;
      keyRaster["y"] = event.touches[0].clientY;
    } else {
      keyRaster["x"] = event.pageX;
      keyRaster["y"] = event.pageY;
    }
  };

  const continueRaster = (event) => {
    if (keyRaster["active"]) {
      let cc = [0, 0];
      if (event.touches) {
        cc = [event.touches[0].clientX, event.touches[0].clientY];
      } else {
        cc = [event.pageX, event.pageY];
      }

      if (Number(mainView.getRotation()) === 0) {
        keyRaster["nCoords"] = [
          keyRaster["coords"][0] - (cc[0] - keyRaster["x"]),
          keyRaster["coords"][1] + (cc[1] - keyRaster["y"]),
        ];
      } else {
        let deltaX = cc[0] - keyRaster["x"];
        let deltaY = cc[1] - keyRaster["y"];
        let dist = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
        let ang = mainView.getRotation() - Math.atan2(deltaY, deltaX);
        let mx = Math.round(dist * Math.sin(ang) * 100) / 100;
        let my = Math.round(dist * Math.cos(ang) * 100) / 100;
        keyRaster["nCoords"] = [
          keyRaster["coords"][0] - my,
          keyRaster["coords"][1] - mx,
        ];
      }

      keyRaster["feature"].getGeometry().setCoordinates(keyRaster["nCoords"]);
      mapRef.current.getView().setCenter(keyRaster["nCoords"]);
    }
  };

  const finishRaster = () => {
    keyRaster["active"] = false;
    keyRaster["coords"] = keyRaster["nCoords"];
  };

  const confirmPosition = () => {
    setBoard(0);
    keyRaster["origin"] = keyRaster["coords"] = keyRaster["nCoords"];
    updatePointCoords();
  };

  const updatePointCoords = async () => {
    var coords2;
    if (keyRaster.nCoords[0] === "del") {
      coords2 = keyRaster.nCoords;
    } else {
      coords2 = transform(keyRaster["nCoords"], "EPSG:3857", "EPSG:4326");
    }
    updateCheck("np", {
      code: keyRaster["id"],
      ter: keyRaster["feature"].get("map"),
      lat: coords2[0],
      lon: coords2[1],
      delete: false,
    });
  };

  const resetPosition = () => {
    keyRaster["feature"].getGeometry().setCoordinates(keyRaster["origin"]);
    keyRaster["coords"] = keyRaster["origin"];
    mapRef.current
      .getView()
      .animate({ center: keyRaster["origin"], duration: 500 });

    if (keyRaster["feature"].get("group") === "np") {
      points_layer.getSource().removeFeature(keyRaster["feature"]);
    }
    setBoard(0);
    clearRaster();
  };

  const clearRaster = () => {
    keyRaster = { ...defRaster };
    setBoard(0);
  };

  const deletePosition = () => {
    openBox2(false);
    Swal.fire({
      title: "Desea eliminar esta punto",
      text: "datos",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "red",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      //points_layer.getSource().removeFeatures(layer.getSource().getFeatureBy('myId', myID));

      if (result.isConfirmed) {
        let exist_x = getFeat("np_" + keyRaster["id"], points_layer);
        if (exist_x) {
          await writeRaster(exist_x);
          keyRaster.nCoords = ["del", "del"];
          //keyRaster.feature = exist_x
          updatePointCoords();
        }
      }
    });
  };

  const addAddress = (data) => {
    Swal.fire({
      title: strLang("addnewaddress"),
      html:
        `<input id="swal-input1" placeholder="` +
        strLang("name") +
        `" class="swal2-input" style="margin:0px; margin-bottom:10px" value="${data[0]}">` +
        `<input id="swal-input2" placeholder="` +
        strLang("observations") +
        `" class="swal2-input" style="margin:0px" value="${data[1]}">`,
      showCancelButton: true,
      confirmButtonText: strLang("save"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value[0].trim() === "") {
          Swal.fire({
            title: strLang("addname"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: strLang("ok"),
          }).then(() => {
            addAddress(result.value);
          });
        } else {
          Swal.fire({
            title: strLang("saving"),
            inputValidator: (value) => {
              //console.log(value);
            },
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              //clearInterval(timerInterval)
            },
          });
          updateCheck("ADDR", {
            name: result.value[0].trim(),
            obs: result.value[1].trim(),
          });
        }
      }
    });
  };

  const updatePosition = () => {
    let _np = getFeat("np_" + keyRaster["id"], points_layer);
    if (_np) {
      keyRaster["coords"] = keyRaster["origin"] = _np
        .getGeometry()
        .getCoordinates();
      let coords2 = transform(
        _np.getGeometry().getCoordinates(),
        "EPSG:3857",
        "EPSG:4326"
      );
      updateCheck("np", {
        code: keyRaster["id"],
        ter: keyRaster["feature"].get("map"),
        lat: coords2[0],
        lon: coords2[1],
        delete: true,
      });
    }
  };

  const mgsPosition = () => {
    Swal.fire({
      title: "¿Desea actualizar la posición?",
      showCancelButton: true,
      confirmButtonText: strLang("update"),
      confirmButtonColor: "green",
      cancelButtonText: strLang("cancel"),
      showDenyButton: true,
      denyButtonText: strLang("delete"),
      denyButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        updatePosition();
      }
      if (result.isDenied) {
        deletePosition();
      }
    });
  };

  const askClean = () => {
    Swal.fire({
      title: strLang("askclean"),
      showCancelButton: true,
      confirmButtonText: strLang("clean"),
      confirmButtonColor: "red",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        updateCheck("CLEAN", {});
      }
    });
  };

  const filterPoint = (_id) => {
    if (sectionId == _id) {
      setSectionId(-1);
      points_layer
        .getSource()
        .getFeatures()
        .forEach((c) => {
          c.setStyle(
            newStyle(
              c.get("point"),
              checkAllDone(c.get("id"), allAddress, c.get("color")[0])
            )
          );
        });
    } else {
      setSectionId(_id);
      points_layer
        .getSource()
        .getFeatures()
        .forEach((c) => {
          //console.log(c.get("section"), _id);
          if (Number(c.get("section")) === Number(_id)) {
            c.setStyle(
              newStyle(
                c.get("point"),
                checkAllDone(c.get("id"), allAddress, c.get("color")[0])
              )
            );
          } else {
            c.setStyle(new Style({}));
          }
        });
    }
  };

  const openSection = async () => {
    var numberslist = { 0: "" };
    var _value = 0;
    allSections.forEach((o) => {
      if (o.ID == keyRaster["section"]) {
        _value = o.ID;
      }
      numberslist[String(o.ID)] = o.NAME;
    });

    const { value: gdata } = await Swal.fire({
      title: strLang("point") + " " + keyRaster["feature"].get("point"),
      input: "select",
      inputOptions: numberslist,
      inputValue: String(_value),
      confirmButtonText: strLang("save"),
      confirmButtonColor: "#5091FF",
      showCancelButton: true,
      cancelButtonText: strLang("cancel"),
      inputValidator: (value) => {
        return new Promise((resolve) => {
          resolve();
        });
      },
    });

    if (gdata) {
      updateCheck("SECTION", { section: gdata });
    }
  };

  const editSections = () => {
    Swal.fire({
      title: "", //keyRaster['feature'].get('point'),
      /*html:
      `<input id="swal-input1" placeholder="Nombre" class="swal2-input" style="margin:0px; margin-bottom:10px" value="${o.NOM}">` +
      `<input id="swal-input2" placeholder="Observaciones" class="swal2-input" style="margin:0px" value="${o.OBSER}">`,*/
      input: "text",
      //inputValue: o.NOTAS,
      showCancelButton: true,
      confirmButtonText: "Agregar",
      confirmButtonColor: "#5091FF",
      showDenyButton: true,
      denyButtonText: `Eliminar`,
      denyButtonColor: "red",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      /*result.isConfirmed && updateCheck('NOTAS', {index: index, data: o, value: result.value.trim()})
      result.isDenied &&  Swal.fire({
        title: 'Desea eliminar este local',
        confirmButtonText: 'Eliminar',
        confirmButtonColor:'red',
        showCancelButton: true,
        cancelButtonText:'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          updateCheck('DELETE', {index: index, data: o, value: o.ID})
        }
      })*/
    });
  };

  const askRoute = () => {
    Swal.fire({
      title: strLang("wantroute"),
      confirmButtonText: "Google Maps",
      confirmButtonColor: "#5091FF",
      showDenyButton: true,
      denyButtonText: `Waze`,
      denyButtonColor: "red",
      showCancelButton: true,
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      let _coo = transform(keyRaster["coords"], "EPSG:3857", "EPSG:4326");
      if (result.isConfirmed) {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${_coo[1]},${_coo[0]}`,
          "_blank"
        );
      }
      if (result.isDenied) {
        window.open(
          `https://waze.com/ul?ll=${_coo[1]},${_coo[0]}&navigate=yes`,
          "_blank"
        );
      }
    });
  };

  const activateEdition = (_value = !editPoly, open_box = true) => {
    setActivatePolyEdit(_value);
    editPoly = _value;
    if (area_layer.getSource().getFeatures().length === 0 && terId > 0) {
      drawInteraction.setActive(_value);
      areaInteraction.setActive(false);
      layersInteraction.setActive(false);
    } else {
      drawInteraction.setActive(false);
      areaInteraction.setActive(_value);
      layersInteraction.setActive(_value);
      if (!editPoly) {
        areaInteraction.getFeatures().clear();
        setMoreOptions(false);
        setOpenColor(false);
        setLimitColor("");
        terId === "all" && setTitle("");
      }
    }
    if (open_box) {
      openBox2(false);
    }
  };

  const removeLimite = () => {
    if (areaRaster) {
      Swal.fire({
        title: strLang("deleteArea"),
        showCancelButton: true,
        confirmButtonText: strLang("delete"),
        confirmButtonColor: "red",
        cancelButtonText: strLang("cancel"),
      }).then((result) => {
        result.isConfirmed && saveLimit(true);
      });
    }
  };

  const openChroma = () => {
    function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }

    if (
      !opencolor &&
      areaRaster.feature.get("color") != null &&
      areaRaster.feature.get("color").split(",").length === 3
    ) {
      let _color = areaRaster.feature.get("color").split(",");
      setLimitColor(
        "#" +
          componentToHex(Number(_color[0])) +
          componentToHex(Number(_color[0])) +
          componentToHex(Number(_color[0]))
      );
    }
    setOpenColor(!opencolor);
  };

  const changeColor = () => {
    if (areaRaster.newColor) {
      let _style = new Style({
        fill: new Fill({
          color: `rgba(${areaRaster.newColor}, 0.05)`,
          weight: 1,
        }),
        stroke: new Stroke({
          color: `rgba(${areaRaster.newColor}, 0.5)`,
          width: 3,
        }),
      });
      areaRaster.feature.setStyle(_style);
      areaRaster.feature.set("color", areaRaster.newColor);
    }
  };

  const selectColor = (color) => {
    setLimitColor(color.hex);
    let _color = [];
    ["r", "g", "b"].map((o) => {
      _color.push(color.rgb[o]);
      return true;
    });

    _color = _color.join(",");
    areaRaster.newColor = _color;
    changeColor();
  };

  const selectColorPoint = (color) => {
    if (color.hex !== "#000000") {
      color.hex === "#ff0000" ? setPPColor(false) : setPPColor(color.hex);
      keyRaster["feature"].set("color", writeColor(color.hex));
      keyRaster["feature"].setStyle(
        newStyle(
          keyRaster["point"],
          checkAllDone(
            keyRaster["id"],
            allAddress,
            color.hex === "#ff0000" ? false : color.hex
          )
        )
      );

      updateCheck("COLOR", {
        code: keyRaster["id"],
        color: color.hex === "#ff0000" ? "" : color.hex,
      });
      setOpenColorPoint(0);
    }
  };

  const openSectionBox = () => {
    if (infoSection.open) {
      setInfoSection({ ...infoSection, open: false });
    } else {
      setInfoSection({ ...infoSection, open: true });
    }
  };

  const openSectionModal = () => {
    Swal.fire({
      title: strLang("addSeccion"),
      input: "text",
      inputValue: "",
      showCancelButton: true,
      confirmButtonText: strLang("add"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log(result);
        if (result.value.trim() === "") {
          Swal.fire({
            title: strLang("addname"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: strLang("ok"),
          }).then(() => {
            openSectionModal();
          });
        } else {
          Swal.fire({
            title: strLang("saving"),
            inputValidator: (value) => {
              //console.log(value);
            },
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              //clearInterval(timerInterval)
            },
          });
          updateCheck("ADDSECTION", {
            map: infoSection.id,
            name: result.value.trim(),
          });
        }
      }
    });
  };

  const askPDF = () => {
    Swal.fire({
      title: strLang("exportPDF"),
      showCancelButton: true,
      confirmButtonText: strLang("export"),
      //confirmButtonColor: "red",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log(_id);
        Swal.fire({
          title: strLang("saving"),
          inputValidator: (value) => {
            //console.log(value);
          },
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            //clearInterval(timerInterval)
          },
        });
        mapRef.current.getView().fit(area_layer.getSource().getExtent(), {
          padding: [0, 0, 0, 0],
        });

        createPDFMap(title, mapRef.current, allAddress, "letter", 150);
        Swal.close();
      }
    });
  };

  return (
    <div ref={theMapBox} className="submenu" style={{ width: _width + "%" }}>
      <div
        ref={mapElement}
        className="map-container mapBox"
        style={{
          position: "absolute",
          width: dim.main[0] + "%",
          height: dim.main[1] + "%",
          left: 0,
          top: 0,
        }}
      >
        {Number(localStorage.getItem("rol")) > 0 ? (
          <div
            style={{ position: "absolute", top: 25, right: 150, zIndex: 100 }}
          >
            <div
              className="mapTitle"
              onClick={removeLimite}
              style={{
                position: "absolute",
                left: moreOptions ? -55 : 0,
                top: 5,
                borderRadius: 100,
                opacity: moreOptions ? 1 : 0,
                height: 40,
                width: 40,
                transition: "left 0.5s, opacity 0.5s",
              }}
            >
              <CancelIcon style={{ color: "red", fontSize: 40 }} />
            </div>
            <div
              className="mapTitle"
              onClick={openChroma}
              style={{
                left: moreOptions ? -30 : 0,
                top: moreOptions ? 50 : 0,
                borderRadius: 100,
                opacity: moreOptions ? 1 : 0,
                height: 40,
                width: 40,
                padding: 3,
                boxSizing: "border-box",
                transition: "left 0.5s, top 0.5s, opacity 0.5s",
              }}
            >
              <img
                src={croma}
                style={{ width: "100%", height: "100%" }}
                alt={"chroma"}
              />
            </div>
            <div
              className="mapTitle"
              style={{
                right: -20,
                top: opencolor ? 100 : 50,
                width: opencolor ? 210 : 0,
                opacity: opencolor ? 1 : 0,
                overflow: "hidden",
                transition: "top 0.25s, opacity 0.25s, width 0.25s",
              }}
            >
              <GithubPicker
                color={limitColor}
                colors={[
                  "#B80000",
                  "#DB3E00",
                  "#FCCB00",
                  "#008B02",
                  "#006B76",
                  "#1273DE",
                  "#004DCF",
                  "#5300EB",
                ]}
                triangle={"hide"}
                onChangeComplete={selectColor}
              />
            </div>
            <div
              className="mapTitle"
              style={{
                borderRadius: 100,
                height: 50,
                width: 50,
                padding: 4,
                boxSizing: "border-box",
                opacity: activatePolyEdit ? 1 : 0.5,
              }}
            >
              <PolylineIcon
                onClick={() => {
                  activateEdition();
                }}
                style={{ color: "blue", fontSize: 40 }}
              />
            </div>
          </div>
        ) : (
          activeLook > 0 && (
            <img
              ref={lookRef}
              alt="look"
              className="prevent-select"
              src={lookImg}
              onClick={() => {
                if (activeLook === 1) {
                  requestAccess();
                  setActiveLook(2);
                  goTo(fromLonLat([props.gps.longitude, props.gps.latitude]));
                }
                if (activeLook === 2) {
                  setActiveLook(1);
                  revokeAccess();
                }
              }}
              style={{
                position: "absolute",
                opacity: activeLook === 1 ? 0.35 : 1,
                top: 20,
                right: 95,
                zIndex: 100,
                width: 60,
                height: "auto",
              }}
            />
          )
        )}

        <img
          ref={compassRef}
          alt="compass"
          className="prevent-select"
          src={compass}
          onClick={() => {
            mainView.animate({
              rotation: 0,
              duration: 500,
            });
          }}
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 100,
            width: 80,
            height: "auto",
          }}
        />
        <div
          className="mapTitle"
          style={{
            top: 100,
            right: 25,
            borderRadius: 100,
            zIndex: 100,
            height: 50,
          }}
        >
          <RoomIcon
            onClick={() => {
              openBox2("all");
            }}
            style={{ color: "red", fontSize: 50 }}
          />
        </div>
        {Number(localStorage.getItem("rol")) > 0 && terId > 0 && (
          <div
            className="mapTitle"
            style={{
              top: 170,
              right: 25,
              borderRadius: 100,
              zIndex: 100,
              width: 50,
              height: 50,
            }}
          >
            <PictureAsPdfIcon
              onClick={() => {
                askPDF();
              }}
              style={{ color: "blue", fontSize: 40, margin: 5 }}
            />
          </div>
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: board + "%",
            zIndex: 101,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              background: "radial-gradient(rgba(0,0,0,0), rgba(100,0,0,0.25))",
            }}
            onMouseDown={beginRaster}
            onMouseMove={continueRaster}
            onMouseUp={finishRaster}
            onTouchStart={beginRaster}
            onTouchMove={continueRaster}
            onTouchEnd={finishRaster}
          >
            <div
              className="roundButton"
              onClick={confirmPosition}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: 20,
                marginLeft: -40,
              }}
            >
              <CheckCircleIcon style={{ color: "green", fontSize: 30 }} />
            </div>
            <div
              className="roundButton"
              onClick={resetPosition}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: 20,
                marginLeft: 10,
              }}
            >
              <CancelIcon style={{ color: "red", fontSize: 30 }} />
            </div>
          </div>
        </div>
      </div>
      {terId && (
        <div className="mapTitle" style={{ top: 5, left: 5 }}>
          <p
            style={{
              position: "relative",
              paddingLeft:
                (infoSection && infoSection.sections.length > 0) ||
                (Number(localStorage.getItem("rol")) > 0 && terId !== "all")
                  ? 35
                  : 0,
            }}
          >
            {((infoSection && infoSection.sections.length > 0) ||
              (Number(localStorage.getItem("rol")) > 0 && terId !== "all")) && (
              <ViewModuleIcon
                onClick={openSectionBox}
                style={{
                  position: "absolute",
                  border: "solid 2px red",
                  borderRadius: 20,
                  marginTop: -3,
                  left: 0,
                }}
              />
            )}
            <b>{title}</b>
          </p>
        </div>
      )}

      {infoSection && infoSection.open && (
        <div
          className="mapTitle"
          style={{ top: 50, left: 25, cursor: "pointer" }}
        >
          {Number(localStorage.getItem("rol")) > 0 && (
            <div
              onClick={openSectionModal}
              style={{
                position: "relative",
                padding: 3,
                paddingLeft: 30,
                paddingRight: 6,
              }}
            >
              <AddIcon style={{ position: "absolute", left: 5 }} />{" "}
              {strLang("addSeccion")}
            </div>
          )}
          {infoSection.sections.map((o, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: "left",
                  padding: 0,
                  margin: 5,
                  display: "flex",
                  alignContent: "center",
                }}
              >
                {Number(o.ID) === Number(sectionId) ? (
                  <CheckBoxIcon
                    style={{ color: "#289315" }}
                    onClick={() => {
                      filterPoint(o.ID);
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankIcon
                    onClick={() => {
                      filterPoint(o.ID);
                    }}
                  />
                )}
                {o.NAME}
              </div>
            );
          })}
        </div>
      )}

      <div
        ref={leyendRef}
        className="mapBox"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: dim.list[0] + "%",
          height: dim.list[1] + "%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            backgroundColor: "#808080",
            padding: 10,
            display: "flex",
            direction: "row",
            height: 45,
            zIndex: 20,
          }}
        >
          {pointId.id !== -1 && (
            <>
              <div
                className="bottons"
                style={{ backgroundColor: "green", margin: 5 }}
                onClick={() => {
                  beginPosition(true);
                }}
              >
                {strLang("relocate")}
              </div>
              <div
                className="bottons"
                style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
                onClick={() => {
                  addAddress(["", ""]);
                }}
              >
                <AddIcon />
              </div>
              <div
                className="bottons"
                style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
                onClick={() => {
                  askRoute();
                }}
              >
                <NearMeIcon />
              </div>
            </>
          )}
          {Number(localStorage.getItem("rol")) === 0 &&
            pointId.id === "delete" && (
              <div
                className="bottons"
                style={{ backgroundColor: "red", margin: 5 }}
                onClick={deletePosition}
              >
                {strLang("deletex")}
              </div>
            )}
          {Number(localStorage.getItem("rol")) > 0 &&
            pointId.id === "delete" && (
              <div
                className="bottons"
                style={{ backgroundColor: "red", margin: 5, minWidth: 30 }}
                onClick={mgsPosition}
              >
                X
              </div>
            )}
          {Number(localStorage.getItem("rol")) > 0 && pointId.id !== -1 && (
            <>
              <div
                className="bottons"
                style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
                onClick={() => {
                  addNumber();
                }}
              >
                <NumbersIcon />
              </div>
              {pointId.point > 0 && (
                <>
                  <div
                    className="mapTitle"
                    onClick={() => {
                      openColorPoint === 0
                        ? setOpenColorPoint(1)
                        : setOpenColorPoint(0);
                    }}
                    style={{
                      position: "relative",
                      boxSizing: "border-box",
                      borderRadius: 100,
                      height: 40,
                      width: 40,
                      padding: PPColor ? 0 : 3,
                      backgroundColor: PPColor ? PPColor : "white",
                      border: PPColor ? "white 3px solid" : "none",
                      transition: "left 0.5s, top 0.5s, opacity 0.5s",
                      marginLeft: 3,
                    }}
                  >
                    {!PPColor && (
                      <img
                        src={croma}
                        style={{ width: "100%", height: "100%" }}
                        alt={"chroma"}
                      />
                    )}
                  </div>
                  <div
                    className="mapTitle"
                    onMouseLeave={() => {
                      openColorPoint === 2 && setOpenColorPoint(0);
                    }}
                    onMouseOver={() => {
                      openColorPoint === 1 && setOpenColorPoint(2);
                    }}
                    style={{
                      left: 145,
                      top: 55,
                      width: "auto",
                      opacity: openColorPoint === 0 ? 0 : 1,
                      height: openColorPoint === 0 ? 0 : 36,
                      overflow: "hidden",
                      transition: "opacity 0.25s, height 0.25s",
                    }}
                  >
                    <GithubPicker
                      color={limitColor}
                      colors={[
                        "#ff0000",
                        null,
                        "#DB3E00",
                        "#FCCB00",
                        "#006B76",
                        "#1273DE",
                        "#004DCF",
                        "#5300EB",
                      ]}
                      triangle={"hide"}
                      onChangeComplete={selectColorPoint}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {Number(localStorage.getItem("rol")) > 0 &&
            allSections.length > 0 &&
            pointId.id !== -1 && (
              <div
                className="bottons"
                style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
                onClick={() => {
                  openSection();
                }}
              >
                <InputIcon />
              </div>
            )}
          {Number(localStorage.getItem("rol")) > 0 &&
            terId > 0 &&
            pointId.id === -1 && (
              <div
                className="bottons"
                style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
                onClick={() => {
                  askClean();
                }}
              >
                {strLang("clean")}
              </div>
            )}
          {Number(localStorage.getItem("rol")) > 0 && pointId.id === -1 && (
            <div
              className="bottons"
              style={{ backgroundColor: "#0073BE", margin: 5, minWidth: 30 }}
              onClick={() => {
                editSections();
              }}
            >
              {strLang("groups")}
            </div>
          )}
          <div
            className="roundButton"
            onClick={() => {
              openBox2(false);
            }}
            style={{ position: "absolute", right: 5, top: 5 }}
          >
            <CancelIcon style={{ color: "red", fontSize: 30 }} />
          </div>
        </div>
        <div style={{ overflowY: "auto", overflowX: "hidden", flex: 1 }}>
          <table style={{ position: "relative", width: "100%" }}>
            <tbody>
              {address.list.map((o, index) => (
                <LineAddress key={index} data={o} index={index} />
              ))}
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <Box xs={{width:'100%'}} className='prevent-select' style={{overflowY:'auto', overflowX:'hidden', flex: 1}}>
          {address.list.map((o, index) => <LineAddress key={index} data={o} index={index} />)}
        </Box> */}
      </div>
    </div>
  );
}

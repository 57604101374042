import "./App.css";

// react
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// components
import Login from "./Login/Login";
import Menu from "./Menu/Menu";
import Loading from "./Loading/Loading";
import Swal from "sweetalert2";
import { strLang } from "./functions/strLang";

document.title = "Mapache";

function App() {
  const [login, setLogin] = useState("loading");

  useEffect(() => {
    if (localStorage.getItem("rol") > 0) {
      Swal.fire({ title: strLang("enterEditor") }).then((result) => {
        if (result.isConfirmed) {
          setLogin(localStorage.getItem("logged") ? true : false);
        }
      });
    } else {
      setLogin(localStorage.getItem("logged") ? true : false);
    }
  }, []);

  const beginLogin = async () => {
    window.location.reload(false);
  };

  const selectScreen = () => {
    switch (login) {
      case "loading":
        return <Loading />;
      case true:
        return <Menu login={login} logout={() => setLogin(false)} />;
      default:
        return <Login login={beginLogin} />;
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <Routes>
        <Route path="/" element={selectScreen()} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useRef, useState } from "react";
import Swal from "sweetalert2";
import "../css/main.css";
import ani1 from "../images/avatars/ani1.png";
import ani2 from "../images/avatars/ani2.png";
import ani3 from "../images/avatars/ani3.png";
import ani4 from "../images/avatars/ani4.png";
import ani5 from "../images/avatars/ani5.png";
import ani6 from "../images/avatars/ani6.png";
import ani7 from "../images/avatars/ani7.png";
import ani8 from "../images/avatars/ani8.png";
import ani9 from "../images/avatars/ani9.png";
import ani10 from "../images/avatars/ani10.png";
import ani11 from "../images/avatars/ani11.png";
import ani12 from "../images/avatars/ani12.png";

import "../css/main.css";
import "../css/background.css";
import { emptys, lang } from "../data/main";
import { strLang } from "../functions/strLang";
import { sendPostServices } from "../services/services";
import { getCurrentDate } from "../utilities/getCurrentDate";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
//var CryptoJS = require("crypto-js");

const avatarImages = [
  [ani1, ani2, ani3],
  [ani4, ani5, ani6],
  [ani7, ani8, ani9],
  [ani10, ani11, ani12],
];
const langGroup = [
  [
    { code: "esp", name: "Español" },
    { code: "ing", name: "English" },
  ],
  [
    { code: "ch", name: "繁體字" },
    { code: "chs", name: "简体字" },
  ],
]; //

export default function Login({ login }) {
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar"));
  const [mlang, setMLang] = useState(localStorage.getItem("lang"));
  const [open, setOpen] = useState(false);
  const [guser, setGUser] = useState(
    localStorage.getItem("name") ? localStorage.getItem("name") : ""
  );
  const [gcode, setGCode] = useState(
    localStorage.getItem("code") ? localStorage.getItem("code") : ""
  );

  const [initWords, setInitWords] = useState({
    avatar: strLang("avatar"),
    user: strLang("initName"),
    code: strLang("initCode"),
    password: strLang("initPassword"),
    accept: strLang("accept"),
    conditionsUse: strLang("conditionsUse"),
    enter: strLang("initEnter"),
    startLogin: strLang("startLogin"),
    badCredentials: strLang("badCredentials"),
    netWorkError: strLang("netWorkError"),
    pleaseSelect: strLang("pleaseSelect"),
    conditionsLeyend: strLang("conditionsLeyend"),
  });

  const name = useRef();
  const group = useRef();
  const password = useRef();
  const policy = useRef();

  //useEffect(() => {}, []);

  const checkCredentials = async () => {
    let missField = false;
    if (!avatar) {
      missField = initWords.avatar;
    } else if (!mlang) {
      missField = "language";
    } else if (name.current.value.trim() === "") {
      missField = initWords.user;
    } else if (group.current.value.trim() === "") {
      missField = initWords.code;
    } else if (password.current.value.trim() === "") {
      missField = initWords.password;
    } else if (!policy.current.checked) {
      missField = initWords.conditionsUse;
    }
    if (missField) {
      Swal.fire({ icon: "error", title: initWords.pleaseSelect + missField });
    } else {
      Swal.fire({ title: initWords.startLogin, showConfirmButton: false });

      let thisDate = getCurrentDate();

      let payloadCheckCredentials = {
        type: "login",
        avatar: avatar,
        code: group.current.value.trim(),
        name: name.current.value.trim(),
        password: password.current.value.trim(),
        date: thisDate,
      };

      let responseCheckCredentials = await sendPostServices(
        payloadCheckCredentials
      );
      console.log(responseCheckCredentials);
      if (responseCheckCredentials.status) {
        console.log("logged");
        Swal.close();

        if (group.current.value.trim() !== localStorage.getItem("group")) {
          localStorage.removeItem("mapid");
          localStorage.removeItem("menu");
        }

        localStorage.setItem("avatar", avatar);
        localStorage.setItem("lang", mlang);
        localStorage.setItem("group", group.current.value.trim());
        localStorage.setItem(
          "tile",
          emptys.includes(responseCheckCredentials.tile)
            ? "OSM"
            : responseCheckCredentials.tile
        );
        localStorage.setItem("name", name.current.value.trim());
        localStorage.setItem("id", Number(responseCheckCredentials.id));
        localStorage.setItem("code", Number(responseCheckCredentials.code));
        localStorage.setItem("code2", Number(responseCheckCredentials.code2));
        localStorage.setItem("rol", Number(responseCheckCredentials.rol));
        localStorage.setItem("day", thisDate);
        localStorage.setItem("token", responseCheckCredentials.token);
        localStorage.setItem("logged", true);
        if (Number(responseCheckCredentials.rol) > 0) {
          localStorage.setItem("groupName", responseCheckCredentials.groupName);
          localStorage.setItem("email", responseCheckCredentials.email);
        }

        login();
      } else if (responseCheckCredentials.netWorkError) {
        Swal.fire({ icon: "error", title: initWords.netWorkError });
      } else {
        Swal.fire({ icon: "error", title: initWords.badCredentials });
      }
    }
  };

  const writeGroupAvatars = (mIndex) => {
    return (
      <div
        key={mIndex}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {avatarImages[mIndex].map((o, index) => (
          <img
            key={index}
            src={o}
            alt={"avatar"}
            className={
              Number(avatar) === 3 * Number(mIndex) + Number(index) + 1
                ? "avaSelected"
                : ""
            }
            onClick={() => {
              setAvatar(3 * Number(mIndex) + Number(index) + 1);
            }}
            style={{ width: 75 }}
          />
        ))}
      </div>
    );
  };

  const writeLang = (mIndex) => {
    return (
      <div
        key={mIndex}
        style={{
          display: "flex",
          direction: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {langGroup[mIndex].map((o, index) => (
          <div
            key={index}
            className="bottons"
            onClick={() => {
              setMLang(o.code);
              setInitWords({
                avatar: lang[o.code]["avatar"],
                user: lang[o.code]["initName"],
                code: lang[o.code]["initCode"],
                password: lang[o.code]["initPassword"],
                accept: lang[o.code]["accept"],
                conditionsUse: lang[o.code]["conditionsUse"],
                enter: lang[o.code]["initEnter"],
                startLogin: lang[o.code]["startLogin"],
                badCredentials: lang[o.code]["badCredentials"],
                netWorkError: lang[o.code]["netWorkError"],
                pleaseSelect: lang[o.code]["pleaseSelect"],
                conditionsLeyend: lang[o.code]["conditionsLeyend"],
              });
            }}
            style={{
              backgroundColor: mlang === o.code ? "blue" : "gray",
              margin: 5,
            }}
          >
            {o.name}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="hero">
        <section>
          <div className="air air1"></div>
          <div className="air air2"></div>
          <div className="air air3"></div>
          <div className="air air4"></div>
        </section>
      </div>

      <div
        style={{
          border: "5px solid #00086A",
          padding: 20,
          borderRadius: 25,
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <p style={{ textAlign: "center" }}>
          <img
            src={require("../images/logo.gif")}
            alt={"logo"}
            style={{ width: 200 }}
          />
        </p>

        {avatarImages.map((o, index) => writeGroupAvatars(index))}
        {langGroup.map((o, index) => writeLang(index))}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: 5,
              width: 200,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              ref={name}
              placeholder={initWords.name}
              style={{ width: "90%" }}
              value={guser}
              onChange={(e) => setGUser(e.target.value)}
            />
          </div>

          <div
            style={{
              padding: 5,
              width: 200,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              ref={group}
              placeholder={initWords.code}
              style={{ width: "90%" }}
              value={gcode}
              onChange={(e) => setGCode(e.target.value)}
            />
          </div>

          <div
            style={{
              paddingLeft: 5,
              padding: 5,
              width: 200,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              ref={password}
              placeholder={initWords.password}
              style={{ width: "90%" }}
              type="password"
            />
          </div>
        </div>
        <div style={{ paddingLeft: 10, paddingBottom: 20 }}>
          <input ref={policy} type="checkbox" /> {initWords.accept}
          <span
            onClick={() => {
              setOpen(true);
            }}
          >
            {initWords.conditionsUse.toLowerCase()}
          </span>
        </div>
        <div
          className="bottons"
          onClick={checkCredentials}
          style={{ backgroundColor: "#00086A", margin: 5 }}
        >
          {initWords.enter}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <div
              className="mapTitle"
              onClick={() => {
                setOpen(false);
              }}
              style={{
                position: "absolute",
                right: 5,
                top: 5,
                borderRadius: 100,
                height: 40,
                width: 40,
                transition: "left 0.5s, opacity 0.5s",
              }}
            >
              <CancelIcon style={{ color: "red", fontSize: 40 }} />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              {initWords.conditionsUse}
            </Typography>
            {initWords.conditionsLeyend.map((o, index) => {
              return o === "" ? (
                <div key={index} style={{ height: 20 }}></div>
              ) : (
                <div key={index} dangerouslySetInnerHTML={{ __html: o }}></div>
              );
            })}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 5,
  p: 4,
};

import React, { useState, useEffect, useRef } from "react";
import "../css/main.css";

import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import ReplayIcon from "@mui/icons-material/Replay";
import { getDaysOfMonth } from "../utilities/getCurrentDate";
import Swal from "sweetalert2";

import { strLang } from "../functions/strLang";
import { getMainInfo } from "../services/menu";
import { updateUser, updateCheck } from "../services/territories";
import useScreenSize from "../utilities/getDimentions";
import { checkDate } from "../functions/checkDate";
import { checkHour } from "../functions/checkHour";
import { useSwipeable } from "react-swipeable";
import { createPDFSchedule } from "../functions/createPDF";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  position: "relative",
  color: theme.palette.text.secondary,
}));

const dayName = strLang("daysName");
const dayNameShort = strLang("daysNameShort");
let activityData = {};
var mapsList = [];
var tempHour = false;
var scrollPosition = 0;

export default function Schedule(props) {
  const screenSize = useScreenSize();
  const [_width, set_Width] = useState(0);
  const [currentDate, setCurrentDate] = useState("");
  const [currentAct, setCurrentAct] = useState([]);
  const [title, setTitle] = useState(false);
  const [daysInfo, setDaysInfo] = useState([]);
  const [dm, setDm] = useState(screenSize.width > 960 ? dayName : dayNameShort);
  //const [mapsList, setMapsList] = useState([]);

  const [secondFlex, setSecondFlex] = useState(
    screenSize.width > 960 ? 0 : 0.5
  );
  const secondBox = useRef();

  const handleScroll = (element) => {
    let tbodyRef = document
      .getElementById("calendarTable")
      .getElementsByTagName("tbody")[0];
    if (tbodyRef.rows.length > 0) {
      const position = element.target.scrollTop;
      scrollPosition = position;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      let inputDate = document.getElementById("calendarSearch").value;
      if (inputDate != "") {
        scrollPosition = 0;
        startWritingCalendar(findNextMonth(inputDate, true));
      }
    },
    onSwipedRight: () => {
      let inputDate = document.getElementById("calendarSearch").value;
      if (inputDate != "") {
        scrollPosition = 0;
        startWritingCalendar(findNextMonth(inputDate));
      }
    },
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    if (secondFlex != 0 && screenSize.width > 960) {
      setSecondFlex(0);
      setDm(dayName);
    }

    if (secondFlex === 0 && screenSize.width <= 960) {
      setSecondFlex(0.5);
      setDm(dayNameShort);
    }
  }, [screenSize.width]);

  useEffect(() => {
    console.log("................", props.login);
    if (props.login) {
      openLoading();
    } else {
      Swal.close();
    }
  }, []);

  useEffect(() => {
    console.log("changing", props.login);
    if (props.login) {
      openLoading();
    } else {
      Swal.close();
    }
  }, [props.login]);

  useEffect(() => {
    set_Width(props.width);
  }, [props.width]);

  useEffect(() => {
    if (props.maps) {
      mapsList = [...props.maps];
      writeCalendar(
        document.getElementById("calendarSearch").value != ""
          ? document.getElementById("calendarSearch").value
          : getCurrentMonth()
      );
    }
  }, [props.maps]);

  const getCurrentMonth = () => {
    let today = new Date();
    let month = today.getMonth() + 1;
    return `${today.getFullYear()}-${month < 10 ? "0" + month : month}`;
  };

  const findNextMonth = (value, increase = false) => {
    value = value.split("-");
    if (increase) {
      value[1] = Number(value[1]) + 1;
    } else {
      value[1] = Number(value[1]) - 1;
    }
    if (value[1] < 1) {
      value[1] = 12;
      value[0] = Number(value[0]) - 1;
    }
    if (value[1] > 12) {
      value[1] = 1;
      value[0] = Number(value[0]) + 1;
    }
    return `${value[0]}-${value[1] < 10 ? "0" + value[1] : value[1]}`;
  };

  const updateDay = (value) => {
    //console.log("value", value);
    setTitle(value);
    let tableCells = document.getElementsByClassName("tableTd");
    for (let a = 0; a < tableCells.length; a++) {
      tableCells[a].style.backgroundColor = "";
    }
    document.getElementById(value).style.backgroundColor = "#FEFFCC";
    if (value in activityData) {
      setCurrentAct(activityData[value]);
    } else {
      setCurrentAct([]);
    }
  };

  const askEdit = (id, name, forceAsk = false) => {
    if (Number(localStorage.getItem("rol")) === 0 && id[1] == "") {
    } else {
      id = id.split(".");
      if (id[3] != "" && id[3] in activityData) {
        updateDay(id[3]);
      }

      if (secondBox.current.style.flex == "0 1 0%" || forceAsk) {
        Swal.fire({
          title: name,
          showConfirmButton: id[1] == "" ? false : true,
          showDenyButton:
            id[2] == 3 &&
            Number(localStorage.getItem("rol")) > 0 &&
            id[6] === "0"
              ? true
              : false,
          showCancelButton: true,
          confirmButtonText: strLang("open"),
          denyButtonText: strLang("edit"),
          confirmButtonColor: "#5091FF",
          cancelButtonText: strLang("close"),
        }).then((result) => {
          if (result.isConfirmed) {
            props.openMap(id[1], name);
            updateUser(id[1]);
          }
          if (result.isDenied) {
            openEditor({
              id: id[0],
              terId: id[1] === "" ? name : id[1],
              ter: id[1] != "" ? true : false,
              status: id[2],
              date: id[3],
              hour: id[4],
              color: id[5],
            });
          }
        });
      }
    }
  };

  const askActivity = (value) => {
    //console.log("askActivity", value);
    Swal.fire({
      title: strLang("whatInsert"),
      showConfirmButton: true,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: strLang("territory"),
      denyButtonText: strLang("event"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      let editorData = {
        id: "",
        terId: false,
        ter: false,
        status: 3,
        date: value,
        hour: tempHour ?? "",
      };
      if (result.isConfirmed) {
        editorData.ter = true;
      }
      if (result.isConfirmed || result.isDenied) {
        openEditor(editorData);
      }
    });
  };

  const openEditor = (data) => {
    let numberslist = "";
    let colorslist = "";
    let colorPart = "";
    if (data.ter) {
      for (let a = 0; a < mapsList.length; a++) {
        numberslist += `<option value=${mapsList[a].ID} ${
          mapsList[a].ID == data.terId ? "selected" : ""
        } >${mapsList[a].NOM}</option>`;
      }
    } else {
      let colorCodes = [
        { color: strLang("colorsList").orange, code: "#FFE8CC" },
        { color: strLang("colorsList").blue, code: "#CCD3FF" },
        { color: strLang("colorsList").red, code: "#FFD7D7" },
        { color: strLang("colorsList").gray, code: "#DFDFDF" },
        { color: strLang("colorsList").yellow, code: "#FFF366" },
        { color: strLang("colorsList").violet, code: "#FCC7FF" },
      ];
      for (let a = 0; a < colorCodes.length; a++) {
        colorslist += `<option value=${colorCodes[a].code} ${
          colorCodes[a].code == data.color ? "selected" : ""
        } >${colorCodes[a].color}</option>`;
      }
      colorPart = `<select id='swal-input4' class='swal2-select' style="margin:5px">${colorslist}</select>`;
    }

    let mainPart = data.ter
      ? `<select id='swal-input1' class='swal2-select' style="margin:5px">${numberslist}</select>`
      : `<input id="swal-input1" placeholder="${strLang(
          "name"
        )}" class="swal2-input" style="margin:5px" value="${
          data.terId == false ? "" : data.terId
        }">`;

    Swal.fire({
      title: strLang("whatInsert"),
      html:
        mainPart +
        `<div><input id="swal-input2" class="swal2-input" type="date" style="margin:5px" value="${data.date}"></div>` +
        `<div><input id="swal-input3" class="swal2-input" type="time" style="margin:5px" value="${data.hour}"></div>` +
        colorPart,
      showConfirmButton: true,
      showDenyButton: data.terId ? true : false,
      showCancelButton: true,
      confirmButtonText: strLang("save"),
      denyButtonText: strLang("delete"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
      preConfirm: () => {
        return {
          name: document.getElementById("swal-input1").value.trim(),
          date: document.getElementById("swal-input2").value,
          hour: document.getElementById("swal-input3").value,
          color: document.getElementById("swal-input4")
            ? document.getElementById("swal-input4").value
            : "",
        };
      },
    }).then((result) => {
      if (result.isConfirmed || result.isDenied) {
        if (
          (result.value.name === "" ||
            result.value.date === "" ||
            result.value.hour === "") &&
          result.isConfirmed
        ) {
          let fieldName =
            result.value.name === ""
              ? strLang("title")
              : result.value.date === ""
              ? strLang("date")
              : result.value.hour === ""
              ? strLang("hour")
              : "";
          Swal.fire({
            title: `${strLang("missingField")} ${fieldName}`,
          }).then((result2) => {
            if (result2.isDismissed || result2.isConfirmed) {
              openEditor({
                ...data,
                terId: result.value.name != "" ? result.value.name : data.terId,
                date: result.value.date != "" ? result.value.date : data.date,
                hour: result.value.hour != "" ? result.value.hour : data.hour,
                color:
                  result.value.color != "" ? result.value.color : data.color,
              });
            }
          });
        } else {
          tempHour = result.value.hour;
          if (result.isConfirmed) {
            openLoading();
            updateActivity({ ...data, ...result.value });
          }
          if (result.isDenied && data.id != "") {
            askDelete(data.id);
          }
        }
      }
    });
  };

  const updateActivity = async (data) => {
    //console.log(data);
    let payload = {
      type: "addActivity",
      code: localStorage.getItem("code2"),
      COD: data.id,
      GRUPO: data.ter ? 0 : localStorage.getItem("code2"),
      TER: data.ter ? data.name : "",
      NOMBRE: data.ter ? "" : data.name,
      FECHA: data.date + " " + data.hour,
      STA: data.status,
      COLOR: data.color,
    };
    //console.log("payload", payload);
    let responseActivity = await getMainInfo(payload);
    //console.log(responseActivity);
    if (responseActivity.status) {
      reloadCalendar();
    } else {
      openError();
    }
  };

  const askDelete = async (id) => {
    Swal.fire({
      title: strLang("deleteActivity"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: strLang("delete"),
      confirmButtonColor: "red",
      cancelButtonText: strLang("cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        let payload = {
          type: "deleteActivity",
          code: localStorage.getItem("code2"),
          COD: id,
        };
        //console.log("payload", payload);
        let responseActivity = await getMainInfo(payload);
        //console.log(responseActivity);
        if (responseActivity.status) {
          reloadCalendar();
        } else {
          openError();
        }
      }
    });
  };

  const writeCalendar = async (dateValue) => {
    let splitDate = dateValue.split("-");
    let previousMonth = Number(splitDate[1]) - 1;
    let nextMonth = Number(splitDate[1]) + 1;
    let theMonths = {
      previous: `${
        previousMonth < 1 ? Number(splitDate[0]) - 1 : splitDate[0]
      }-${
        previousMonth < 1
          ? 12
          : previousMonth < 10
          ? "0" + previousMonth
          : previousMonth
      }`,
      current: dateValue,
      next: `${nextMonth > 12 ? Number(splitDate[0]) + 1 : splitDate[0]}-${
        nextMonth > 12 ? "01" : nextMonth < 10 ? "0" + nextMonth : nextMonth
      }`,
    };

    //console.log("theMonths: ", theMonths);

    //console.log("writeCalendar: ", dateValue);
    setCurrentDate(dateValue);
    let days = getDaysOfMonth(dateValue.split("-")[0], dateValue.split("-")[1]);
    //console.log(days);

    let tbodyRef = document
      .getElementById("calendarTable")
      .getElementsByTagName("tbody")[0];

    while (tbodyRef.rows.length > 0) {
      tbodyRef.rows[0].remove();
    }

    let rowNum = 0;
    let countDay = days.dayWeek > 0 ? days.lastDays - days.dayWeek + 1 : 1;
    let tdClass = countDay === 1 ? "tableTd" : "tableTd outMonth";
    let idPrefix = countDay === 1 ? theMonths.current : theMonths.previous;
    activityData = {};

    let responseActivity = await getMainInfo({
      type: "activity",
      code: localStorage.getItem("code2"),
      id: localStorage.getItem("id"),
      start:
        countDay === 1
          ? `${theMonths.current}-01`
          : `${theMonths.previous}-${countDay}`,
      days: countDay === 1 ? days.days + 6 : days.dayWeek + 1 + days.days + 5,
    });
    //console.log("responseActivity: ", responseActivity);
    //let dayKey = "";
    if (responseActivity.status) {
      for (let h = 0; h < responseActivity.activity.length; h++) {
        var dateSplit = responseActivity.activity[h].FECHA.split(" ");
        var writeData = false;

        if (!activityData.hasOwnProperty(dateSplit[0])) {
          //dayKey = dateSplit[0];
          activityData[dateSplit[0]] = [];
          writeData = true;
        } else if (
          activityData[dateSplit[0]].findIndex(
            (p) => p.ter == responseActivity.activity[h].TER
          ) == -1 ||
          responseActivity.activity[h].STA == 3
        ) {
          writeData = true;
        }

        if (writeData) {
          var extraDates = false;
          if (responseActivity.activity[h].TER > 0) {
            extraDates = responseActivity.activity.filter(
              (s) =>
                s.TER === responseActivity.activity[h].TER &&
                s.STA < 3 &&
                s.FECHA.split(" ")[0] == dateSplit[0]
            );
            if (extraDates.length > 0) {
              extraDates = {
                initial: extraDates[0].FECHA.split(" ")[1],
                final: extraDates[extraDates.length - 1].FECHA2.split(" ")[1],
                done: extraDates[extraDates.length - 1].DONE,
                total: extraDates[extraDates.length - 1].TOTAL,
              };
            } else {
              extraDates = false;
            }
          }

          activityData[dateSplit[0]].push({
            id: responseActivity.activity[h].COD,
            name: responseActivity.activity[h].TER
              ? responseActivity.activity[h].NOM
              : responseActivity.activity[h].NOMBRE,
            ter: responseActivity.activity[h].TER,
            status: responseActivity.activity[h].STA,
            hour: dateSplit[1],
            color: responseActivity.activity[h].COLOR,
            finish: 0,
            done: extraDates
              ? extraDates.done
              : responseActivity.activity[h].DONE,
            total: extraDates
              ? extraDates.total
              : responseActivity.activity[h].TOTAL,
            extraDates,
          });
        }
      }
    }
    //console.log("activities", activityData);
    let tempDaysInfo = [];

    while (countDay) {
      var newRow = tbodyRef.insertRow();
      tempDaysInfo.push([]);
      var currentWeek = tempDaysInfo[tempDaysInfo.length - 1];
      for (let a = 0; a < 7; a++) {
        var newCell = newRow.insertCell();
        currentWeek.push({});
        var currentDayInfo = currentWeek[currentWeek.length - 1];
        currentDayInfo.day = countDay;
        currentDayInfo.activities = [];
        if (countDay === 1) {
          tdClass = rowNum === 0 ? "tableTd" : "tableTd outMonth";
          idPrefix = rowNum === 0 ? theMonths.current : theMonths.next;
        }

        newCell.className = tdClass;
        newCell.id = `${idPrefix}-${countDay < 10 ? "0" + countDay : countDay}`;
        newCell.onclick = (event) => {
          let miniSplit = event.target.id.split(".");
          miniSplit = miniSplit[3] ?? miniSplit[0];
          if (miniSplit != "") {
            updateDay(miniSplit);
          }
        };
        var newText = document.createElement("strong");
        newText.onclick = (event) => {
          updateDay(event.target.parentElement.id);
        };
        newText.appendChild(document.createTextNode(countDay));
        newCell.appendChild(newText);

        if (Number(localStorage.getItem("rol")) > 0) {
          var newDiv = document.createElement("div");
          newDiv.className = "addActivity";
          newDiv.appendChild(document.createTextNode("+"));
          newDiv.onclick = (event) => {
            askActivity(event.target.parentElement.id);
          };
          newCell.appendChild(newDiv);
        }

        if (newCell.id in activityData) {
          for (let z = 0; z < activityData[newCell.id].length; z++) {
            currentDayInfo.activities.push(activityData[newCell.id][z]);
            var newDiv = document.createElement("div");
            newDiv.className =
              activityData[newCell.id][z].status === 3 &&
              !activityData[newCell.id][z].extraDates
                ? "activity activityGreen"
                : "activity";

            if (activityData[newCell.id][z].color != "") {
              newDiv.style.backgroundColor = activityData[newCell.id][z].color;
            }

            newDiv.id = `${activityData[newCell.id][z].id}.${
              activityData[newCell.id][z].ter
            }.${activityData[newCell.id][z].status}.${newCell.id}.${
              activityData[newCell.id][z].hour
            }.${activityData[newCell.id][z].color}.${
              activityData[newCell.id][z].extraDates ? "1" : "0"
            }`;
            newDiv.setAttribute("data-value", activityData[newCell.id][z].name);

            newDiv.onclick = (event) => {
              var idData = event.target.id;
              var nameData = event.target.getAttribute("data-value");
              if (
                event.target.id === "" ||
                !event.target.getAttribute("data-value")
              ) {
                var idData = event.target.parentElement.id;
                var nameData =
                  event.target.parentElement.getAttribute("data-value");
              }
              askEdit(idData, nameData);
            };
            newDiv.innerHTML = `<b>${activityData[newCell.id][z].name}</b>${
              activityData[newCell.id][z].ter != ""
                ? `${getMapInfo(
                    activityData[newCell.id][z],
                    "totals",
                    "<br/>"
                  )}`
                : ""
            }${
              activityData[newCell.id][z].status === 3
                ? `<br>${strLang("hour")}: ` +
                  checkHour(activityData[newCell.id][z].hour)
                : ""
            }${writeExtraDates(
              activityData[newCell.id][z].extraDates,
              "<br/>"
            )}`;
            newCell.appendChild(newDiv);
          }
        }

        if (a === 6 && (countDay < 7 || countDay === days.days) && rowNum > 0) {
          countDay = "finish";
        } else if (
          (countDay === days.lastDays && rowNum === 0) ||
          (countDay === days.days && rowNum > 0)
        ) {
          countDay = 1;
        } else {
          countDay++;
        }
      }
      if (countDay === "finish") {
        break;
      }
      rowNum++;
    }
    setDaysInfo([...tempDaysInfo]);
    changeTableScroll();
  };

  const changeTableScroll = () => {
    document.getElementById("tableBox").scrollTop = scrollPosition;
    Swal.close();
  };

  const writeExtraDates = (data, profix = "") => {
    //console.log("data", data);
    if (data) {
      if (profix === "") {
        return (
          <>
            <div>{strLang("start") + ": " + checkHour(data.initial)}</div>
            <div>{strLang("end") + ": " + checkHour(data.final)}</div>
          </>
        );
      } else {
        return `${profix}${strLang("start")}: ${checkHour(
          data.initial
        )}${profix}${strLang("end")}: ${checkHour(data.final)}`;
      }
    } else {
      return "";
    }
  };

  const startPrint = () => {
    createPDFSchedule(
      document.getElementById("calendarSearch").value,
      dayName,
      daysInfo,
      "letter",
      true
    );
    console.log(daysInfo);
  };

  const getMapInfo = (data, field, profix = "") => {
    let dataResult = "";
    if (data.status == 3 && !data.extraDates) {
      const obj = mapsList.filter((item) => {
        return item.ID == data.ter;
      });
      if (obj.length > 0) {
        if (field === "totals") {
          dataResult = `${strLang("addresses")}: ${
            obj[0].HECHOS > 0 ? obj[0].HECHOS + " / " : ""
          }${obj[0].TOTAL}`;
        }
      }
    } else {
      dataResult = `${
        data.done > 0 || data.total > 0
          ? `${strLang("record")}: ${data.done} / ${data.total}`
          : ""
      }`;
    }
    return (dataResult != "" ? profix : "") + dataResult;
  };

  const openLoading = () => {
    Swal.fire({
      title: strLang("loading"),
      inputValidator: (value) => {
        //console.log(value);
      },
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        //clearInterval(timerInterval)
      },
    });
  };

  const startWritingCalendar = (value) => {
    if (value != "") {
      openLoading();
      setTitle("");
      setCurrentAct([]);
      writeCalendar(value);
    }
  };

  const reloadCalendar = () => {
    //let value = document.getElementById("calendarSearch").value;
    //console.log(value);
    startWritingCalendar(document.getElementById("calendarSearch").value);
  };

  const openError = () => {
    Swal.fire({
      title: strLang("networkError"),
    });
  };

  return (
    <div
      className="submenu"
      style={{ width: _width + "%", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "#EEEEEE",
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <CalendarMonthIcon style={{ fontSize: 60 }} />
          <TextField
            id="calendarSearch"
            variant="outlined"
            type="month"
            value={currentDate}
            onChange={(e) => {
              startWritingCalendar(e.target.value);
            }}
          />
          <div
            onClick={() => {
              reloadCalendar();
            }}
            style={{
              backgroundColor: "gray",
              marginLeft: 10,
              borderRadius: 100,
              width: 50,
              height: 50,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ReplayIcon style={{ fontSize: 40, color: "white" }} />
          </div>
          {Number(localStorage.getItem("rol")) < -30 && (
            <div
              onClick={() => {
                startPrint();
              }}
              style={{
                backgroundColor: "gray",
                marginLeft: 10,
                borderRadius: 100,
                width: 50,
                height: 50,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <LocalPrintshopIcon style={{ fontSize: 40, color: "white" }} />
            </div>
          )}
        </div>
      </div>
      <div
        id="tableBox"
        onScroll={handleScroll}
        {...handlers}
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
          backgroundColor: "#EEEEEE",
        }}
      >
        <table
          id="calendarTable"
          className="table"
          style={{ width: "100%", height: "100%" }}
        >
          <thead>
            <tr className="titlecalendar">
              <th>{dm[6]}</th>
              <th>{dm[0]}</th>
              <th>{dm[1]}</th>
              <th>{dm[2]}</th>
              <th>{dm[3]}</th>
              <th>{dm[4]}</th>
              <th>{dm[5]}</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div
        ref={secondBox}
        style={{
          position: "relative",
          flex: secondFlex,
          overflow: "auto",
          backgroundColor: "white",
          padding: secondFlex ? 10 : 0,
        }}
      >
        {title != "" && <div className="titleDate">{checkDate(title)}</div>}
        {title != "" && Number(localStorage.getItem("rol")) > 0 && (
          <AddCircleIcon
            className="addActivity2"
            fontSize="large"
            onClick={() => {
              askActivity(title);
            }}
          />
        )}
        {currentAct.length > 0 ? (
          currentAct.map((o, index) => {
            let idName = `${o.id}.${o.ter}.${o.status}.${title}.${o.hour}.${
              o.color
            }.${o.extraDates ? "1" : "0"}`;
            return (
              <div
                key={index}
                className={
                  o.status === 3 && !o.extraDates
                    ? "activity2 activityGreen2"
                    : "activity2"
                }
                style={{ backgroundColor: o.color }}
                onClick={() => {
                  askEdit(idName, o.name, true);
                }}
              >
                <b>{o.name}</b>
                {o.ter != "" && <div>{getMapInfo(o, "totals")}</div>}
                {o.status === 3 && (
                  <div>{strLang("hour") + ": " + checkHour(o.hour)}</div>
                )}
                {writeExtraDates(o.extraDates)}
              </div>
            );
          })
        ) : (
          <div style={{ textAlign: "center" }}>
            {title != "" ? strLang("noActivities") : ""}
          </div>
        )}
      </div>
    </div>
  );
}

import { strLang } from "./strLang";

const name = ["year", "month", "day"];
const names = ["years", "months", "days"];

export const checkHour = (originalHour) => {
  let theHour = originalHour.split(":");

  switch (localStorage.getItem("lang")) {
    case "esp":
      let intHour = Number(theHour[0]);
      let finalHour = [
        intHour === 0 ? 12 : intHour > 12 ? intHour - 12 : intHour,
        theHour[1],
      ].join(":");
      return [finalHour, intHour > 11 ? "p.m." : "a.m."].join(" ");
    case "ing":
    case "ch":
    case "chs":
    default:
      return [theHour[0], theHour[1]].join(":");
  }
};

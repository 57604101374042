import React, { useState, useEffect } from "react";
import { strLang } from "../functions/strLang";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Section from "./Section";
import packageJson from "../../package.json";
export default function Help(props) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(props.width);
  }, [props.width]);

  const createHRef = (title, url) => {
    return (
      <div>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.open(url, "_blank").focus();
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  return (
    <div
      className="submenu"
      style={{ width: width + "%", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "#EEEEEE",
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "left",
        }}
      >
        <QuestionMarkIcon style={{ fontSize: 60 }} />
      </div>

      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        <Section title={strLang("version")} line={true}>
          <div>MapaCHE {packageJson.version}</div>
        </Section>
        <Section title={strLang("legalInfo")}>
          <div>
            <b>MapaCHE</b>
            <br />
            {createHRef("Condiciones de Uso", "")}
            {createHRef("Política de Privacidad", "")}
          </div>
          <div>
            <b>OpenStreetMap</b>
            <br />
            {createHRef(
              "Condiciones de Uso",
              "https://osmfoundation.org/wiki/Terms_of_Use"
            )}
          </div>
          {/* <div>
            <b>Google</b>
            <br />
            {createHRef(
              "Condiciones de Uso",
              "https://cloud.google.com/maps-platform/terms"
            )}
            {createHRef(
              "Política de Privacidad",
              "https://policies.google.com/privacy?hl=es-419"
            )}
          </div> */}
          <div>
            <b>Bing</b>
            <br />
            {createHRef(
              "Privacidad y cookies",
              "https://privacy.microsoft.com/es-mx/privacystatement"
            )}
            {createHRef(
              "Información legal",
              "https://www.microsoft.com/es-mx/servicesagreement/"
            )}
          </div>
        </Section>
      </div>
    </div>
  );
}

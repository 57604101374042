import React from "react";
import "../css/loading.css";

export default function Loading() {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="rotating"></div>
      <div className="whiteDiv">
        <img
          src={require("../images/icon.png")}
          className="image"
          alt={"logo"}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { strLang } from "../functions/strLang";

import LoadingBox from "../LoadingBox";
import NoResults from "../NoResults";

import MapIcon from "@mui/icons-material/Map";
import MessageIcon from "@mui/icons-material/Message";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ReplayIcon from "@mui/icons-material/Replay";

import Swal from "sweetalert2";
import flagicon from "../TheMap/flag.png";

import "../css/main.css";
import { removeAccents } from "../functions/removeAccents";
import { checkDate } from "../functions/checkDate";
import { checkTime } from "../functions/checkTime";
import { checkHour } from "../functions/checkHour";
import { checkDay } from "../functions/checkDay";
import { updateUser, updateCheck } from "../services/territories";
import { emptys, tileList } from "../data/main";

var totalp = 0;
var mapsList = [];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F3F3F3",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const d = new Date();
var month = d.getMonth();
var lapsus = [];

export default function Territories(props) {
  const [_width, set_Width] = useState(0);
  const [list, setList] = useState([]);
  const [order, setOrder] = useState(Number(localStorage.getItem("ter_order")));
  const [textInput, setTextInput] = useState("");
  const [loading, setLoading] = useState(1);

  useEffect(() => {
    for (let a = 0; a < 6; a++) {
      lapsus.unshift({ name: strLang("monthsname")[month], pos: month });
      month--;
      if (month < 0) {
        month = 11;
      }
    }
  }, []);

  const funcUpdateCheck = async (type, o) => {
    const results = await updateCheck(type, o);
    if (results && results.status) {
      if (type === "HISTER") {
        return results.historial;
      } else {
        props.reload();
      }
    }
    return false;
  };

  useEffect(() => {
    set_Width(props.width);
  }, [props.width]);

  useEffect(() => {
    if (props.maps) {
      //console.log(props.maps);
      totalp = 0;
      props.maps.map((o) => {
        totalp += o.TOTAL;
        return true;
      });

      mapsList = [...props.maps];
      changeOrder(order);
      Swal.close();

      //setList([{ID: "", NOM: strLang('all'), TOTAL:totalp}, ...props.maps])
      //setList([...props.maps])
    }
  }, [props.maps]);

  const filterMap = (txt) => {
    setTextInput(txt);
    txt = txt.trim();
    if (txt === "") {
      setList([{ ID: "", NOM: strLang("all"), TOTAL: totalp }, ...mapsList]);
    } else {
      txt = removeAccents(txt);
      let tempMap = mapsList.filter((o) => {
        let name = removeAccents(o.NOM);
        return name.toLowerCase().includes(txt.toLowerCase());
      });
      setList(tempMap);
    }
    setLoading(0);
  };

  const changeOrder = (_order) => {
    setOrder(_order);

    switch (_order) {
      case 1:
        mapsList.sort((a, b) => {
          return new Date(b.DATE) - new Date(a.DATE);
        });
        break;
      case 2:
        mapsList.sort((a, b) => {
          return new Date(a.DATE) - new Date(b.DATE);
        });
        break;
      default:
        mapsList.sort((a, b) => {
          if (a.NOM.toUpperCase() < b.NOM.toUpperCase()) {
            return -1;
          }
          if (a.NOM.toUpperCase() > b.NOM.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        break;
    }
    //console.log("mapslist: ", mapsList);
    filterMap(textInput);
  };

  const changeName = (id, name, tile) => {
    var numberslist = "";
    tileList.forEach((o) => {
      if (o) {
        numberslist += `<option value='${o === "default" ? "" : o}' ${
          o == tile ? "selected" : ""
        } >${strLang(o, "tileNames")}</option>`;
      }
    });
    Swal.fire({
      title: name,
      html:
        `<input id='name' type='text' class='swal2-input' value='${name}' />` +
        `<select id='tile' class='swal2-select'>${numberslist}</select>`,
      showCancelButton: true,
      confirmButtonText: strLang("change"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
      preConfirm: () => {
        return {
          name: document.getElementById("name").value,
          tile: document.getElementById("tile").value,
        };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log(result);
        if (result.value.name.trim() === "") {
          Swal.fire({
            title: strLang("addname"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: strLang("ok"),
          }).then((result2) => {
            changeName(id, name, tile);
          });
        } else {
          Swal.fire({
            title: strLang("saving"),
            didOpen: () => {
              Swal.showLoading();
            },
          });
          funcUpdateCheck("TERNAME", {
            code: id,
            value: result.value.name.trim(),
            tile: result.value.tile,
          });
        }
      }
    });
  };

  const terNew = () => {
    Swal.fire({
      title: strLang("newter"),
      input: "text",
      inputValue: "",
      showCancelButton: true,
      confirmButtonText: strLang("save"),
      confirmButtonColor: "#5091FF",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log(result);
        if (result.value.trim() === "") {
          Swal.fire({
            title: strLang("addname"),
            icon: "warning",
            confirmButtonColor: "#3085d6",
            confirmButtonText: strLang("ok"),
          }).then(() => {
            terNew();
          });
        } else {
          Swal.fire({
            title: strLang("saving"),
            inputValidator: (value) => {
              //console.log(value);
            },
            didOpen: () => {
              Swal.showLoading();
            },
            willClose: () => {
              //clearInterval(timerInterval)
            },
          });
          funcUpdateCheck("TERNEW", { value: result.value.trim() });
        }
      }
    });
  };

  const deleteTer = (_id) => {
    Swal.fire({
      title: strLang("delter"),
      showCancelButton: true,
      confirmButtonText: strLang("delete"),
      confirmButtonColor: "red",
      cancelButtonText: strLang("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        //console.log(_id);
        Swal.fire({
          title: strLang("saving"),
          inputValidator: (value) => {
            //console.log(value);
          },
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            //clearInterval(timerInterval)
          },
        });
        funcUpdateCheck("TERDEL", { code: _id });
      }
    });
  };

  const openHistorial = async (code, title) => {
    Swal.fire({
      title: strLang("loading"),
      inputValidator: (value) => {
        //console.log(value);
      },
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        //clearInterval(timerInterval)
      },
    });
    const histData = await funcUpdateCheck("HISTER", { code });
    let tableRes = "";
    if (histData && histData.length > 0) {
      histData.map((o, index) => {
        let theDate = o.FECHA.split(" ");

        tableRes += `<tr style='background-color:${
          o.STA == 3 ? "#C4FDBE" : index % 2 == 0 ? "#EAEAEA" : "white"
        }'>
        <td align=left style='padding:10px;'>${checkDate(theDate[0])}</td>
        <td align=left>${checkHour(
          theDate[1] != "00:00:00" ? theDate[1] : o.HORA
        )}</td>
        <td>${checkDay(theDate[0])}</td><td>${
          o.STA == 3
            ? strLang("scheduled")
            : Number(localStorage.getItem("rol")) > 0
            ? o.PER
            : ""
        }</td>
        </tr>`;
      });
      tableRes =
        "<div style='width:100%; max-height:400px; overflow:auto'><table align=center width=100% cellspacing=0>" +
        tableRes +
        "</table><div>";
    }
    Swal.fire({
      title,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: strLang("close"),
      html: `${tableRes}`,
    });
  };

  const saveOrder = (_value) => {
    changeOrder(_value);
    localStorage.setItem("ter_order", _value);
  };

  return (
    <div
      className="submenu"
      style={{ width: _width + "%", display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          backgroundColor: "#EEEEEE",
          paddingTop: 10,
          paddingBottom: 10,
          textAlign: "left",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <MapIcon style={{ fontSize: 60 }} />
          <TextField
            id="outlined-basic"
            value={textInput}
            label={strLang("search")}
            variant="outlined"
            style={{ width: 200 }}
            onChange={(e) => {
              filterMap(e.target.value);
            }}
          />
          <div
            onClick={() => {
              if (loading === 0) {
                setLoading(1);
                props.reload();
              }
            }}
            style={{
              backgroundColor: "gray",
              marginLeft: 10,
              borderRadius: 100,
              width: 50,
              height: 50,
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ReplayIcon style={{ fontSize: 40, color: "white" }} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Number(localStorage.getItem("rol")) > 0 && (
            <div
              className="bottons"
              onClick={() => {
                terNew();
              }}
              style={{ backgroundColor: "green" }}
            >
              {strLang("new")}
            </div>
          )}
          <div
            className="bottons"
            onClick={() => {
              if (order !== 0) {
                saveOrder(0);
              }
            }}
            style={{ backgroundColor: order === 0 ? "blue" : "gray" }}
          >
            A - B
          </div>
          <div
            className="bottons"
            onClick={() => {
              if (order !== 1) {
                saveOrder(1);
              }
            }}
            style={{ backgroundColor: order === 1 ? "blue" : "gray" }}
          >
            {strLang("mostrecent")}
          </div>
          <div
            className="bottons"
            onClick={() => {
              if (order !== 2) {
                saveOrder(2);
              }
            }}
            style={{ backgroundColor: order === 2 ? "blue" : "gray" }}
          >
            {strLang("mostoverdue")}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          flex: 1,
          overflow: "auto",
          backgroundColor: "white",
        }}
      >
        {loading === 1 ? (
          <LoadingBox />
        ) : list.length === 0 ? (
          <NoResults />
        ) : (
          <Grid
            container
            spacing={5}
            padding={2}
            columns={{ xs: 1, sm: 8, md: 20 }}
          >
            {list.map((o, index) => (
              <Grid item xs={1} sm={4} md={4} key={index}>
                <Item
                  style={{
                    textAlign: "left",
                    position: "relative",
                    cursor: "pointer",
                    height: "100%",
                    backgroundColor:
                      o.HECHOS === o.TOTAL
                        ? "#D7E6FF"
                        : o.HECHOS > 0
                        ? "#CCEACA"
                        : "",
                  }}
                  onClick={() => {
                    if (!o.ID && Number(localStorage.getItem("rol")) > 0) {
                      Swal.fire({
                        title: strLang("allterAsk"),
                        showCancelButton: true,
                        confirmButtonText: strLang("open"),
                        confirmButtonColor: "#5091FF",
                        cancelButtonText: strLang("cancel"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          props.openMap("all", "");
                        }
                      });
                    }
                  }}
                >
                  <div
                    onClick={() => {
                      if (o.ID > 0) {
                        Swal.fire({
                          title: strLang("terAsk"),
                          showCancelButton: true,
                          confirmButtonText: strLang("open"),
                          confirmButtonColor: "#5091FF",
                          cancelButtonText: strLang("cancel"),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            props.openMap(o.ID, o.NOM);
                            updateUser(o.ID);
                          }
                        });
                      }
                    }}
                  >
                    <b style={{ fontSize: "large" }}>{o.NOM}</b>
                    {o.ID === "" && (
                      <p style={{ marginBottom: 0, marginTop: 0 }}>
                        {strLang("territories")}: {list.length - 1}
                      </p>
                    )}
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      {strLang("addresses")}:{" "}
                      {o.HECHOS > 0 ? o.HECHOS + " / " : ""}
                      {o.TOTAL}
                    </p>
                    {o.DATE ? (
                      <>
                        <p style={{ marginBottom: 0, marginTop: 0 }}>
                          {strLang("date")}: {checkDate(o.DATE)}
                        </p>
                        <p>
                          {strLang("time")}: {checkTime(o.DATE)}
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    {o.FLAGS > 0 && (
                      <img src={flagicon} alt="Logo" style={{ height: 20 }} />
                    )}
                    {o.NP > 0 && (
                      <LocationOnIcon style={{ color: "red", fontSize: 20 }} />
                    )}
                    {o.NOTES > 0 && (
                      <MessageIcon style={{ color: "#FF5900", fontSize: 20 }} />
                    )}
                  </div>
                  {Number(localStorage.getItem("rol")) > 0 && o.ID > 0 && (
                    <DriveFileRenameOutlineIcon
                      onClick={() => {
                        changeName(o.ID, o.NOM, o.TILE);
                      }}
                      style={{ color: "#FF5900", fontSize: 30 }}
                    />
                  )}
                  {Number(localStorage.getItem("rol")) > 0 &&
                    o.ID > 0 &&
                    o.TOTAL === 0 && (
                      <DeleteForeverIcon
                        onClick={() => {
                          deleteTer(o.ID);
                        }}
                        style={{ color: "red", fontSize: 30 }}
                      />
                    )}
                  {
                    o.TILE != "" && (
                      <div
                        style={{
                          textAlign: "right",
                          paddingRight: 5,
                          fontWeight: "bold",
                        }}
                      >
                        {strLang(o.TILE, "tileNames")}
                      </div>
                    )
                    //!emptys.includes(strLang(o.TILE, "tileNames")) &&
                  }
                  <div style={{ position: "relative", height: 35 }}></div>
                  {o.ID > 0 && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: 10,
                        backgroundColor: "white",
                        left: 10,
                        right: 10,
                      }}
                      onClick={() => {
                        openHistorial(o.ID, o.NOM);
                      }}
                    >
                      <table style={{ position: "relative", width: "100%" }}>
                        <thead>
                          <tr
                            style={{ backgroundColor: "gray", color: "white" }}
                          >
                            <td style={{ textAlign: "center" }}>
                              {lapsus[0].name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {lapsus[1].name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {lapsus[2].name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {lapsus[3].name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {lapsus[4].name}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {lapsus[5].name}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ height: 20 }}>
                            {lapsus.map((v, index) => (
                              <td key={index} style={{ position: "relative" }}>
                                {o.HISTORIAL["m" + v.pos] &&
                                  o.HISTORIAL["m" + v.pos].map((n, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: "absolute",
                                        left: n[0].POS + "%",
                                        height: 20,
                                        backgroundColor: "red",
                                        width: 3,
                                        top: 0,
                                      }}
                                    ></div>
                                  ))}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </Item>
              </Grid>
            ))}
          </Grid>
        )}

        <div style={{ width: "100%", height: 20 }}></div>
      </div>
    </div>
  );
}

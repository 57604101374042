import axios from "axios";
export const sendPostServices = async (payload) => {
  let data = { success: false, netWorkError: false };
  try {
    //console.log("payload", payload);
    const serviceResponse = await axios
      .post(process.env.REACT_APP_API_URL, JSON.stringify(payload), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      })
      .then((response) => response)
      .catch((error) => {
        console.log("error axios: ", error);
      });
    //console.log("serviceResponse: ", payload.type, serviceResponse);
    if (serviceResponse.data) {
      data = { ...serviceResponse.data };
    }
  } catch (error) {
    //console.log("error sendPostServices:", error);
    data.netWorkError = true;
  }
  return data;
};

import { lang } from "../data/main";

export const strLang = (field, type = false) => {
  let tempLang = "ing";
  if (!["", false, null, undefined].includes(localStorage.getItem("lang"))) {
    tempLang = localStorage.getItem("lang");
  }
  if (lang[tempLang][field] != "" || Array.isArray([lang[tempLang][field]])) {
    if (type) {
      field = lang[tempLang][type][field];
    } else {
      field = lang[tempLang][field];
    }
  } else if (field === "monthsname") {
    field = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
  }
  return field;
};
